const productData = [
  {
    id: 1,
    name: "Collagen Powder",
    reviews: "3628",
    prePrice: "24.99",
    price: "16.99",
    description: [
      " For people looking for a collagen powder that does the job. And tastes and smells neutral, not like sink water and fish pee.",
      "For people who know that consistency is everything. And this collagen powder in your fav hot or cold beverage every morning has your skin lookin RIGHT. Like your queen Cheryl.",
      "For people who want to say NOOOO to bad hair days. And want to say YAASSSS to hair that feels stronger, healthier and softer than silk.",
      "For people who know that collagen won't change their age in people or dog years. But know it will make your joints less stiff and achy.",
      " For people who want to be able to have lovely long and strong nails that make you feel a bit more glam.Warning: Not suitable for Vegetarians or Vegans. But suitable for Halal, Kosher and Keto diets.",
    ],
    ingredients: "Bovine collagen peptides powder.",
    directions:
      "Stir 2 tablespoons into a large cup of water, tea, coffee or juice or add to foods such as porridge, soups and smoothies.",
    images: [
      "https://wellgard.co.uk/cdn/shop/products/collagen-powder.png?v=1632137531&width=1080",
      "https://wellgard.co.uk/cdn/shop/files/collagen-product-pictures-01_3cc50a64-be81-4245-b965-3cc28d551a13.jpg?v=1704897838&width=1080",
      "https://wellgard.co.uk/cdn/shop/files/collagen-product-pictures-02_e34abe42-532f-4d73-ac1f-70b3f6f0e2f9.jpg?v=1704897839&width=1080",
      "https://wellgard.co.uk/cdn/shop/files/collagen-product-pictures-03_01c1d316-00cd-43a0-b875-9c5f5f61660f.jpg?v=1704897838&width=1080",
      "https://wellgard.co.uk/cdn/shop/files/collagen-product-pictures-05_d2fc885c-3cb0-461e-84e7-3d9de7fc9200.jpg?v=1704897840&width=1080",
      "https://wellgard.co.uk/cdn/shop/files/collagen-product-pictures-04_8265c427-349c-4866-aa81-02d3f461fffb.jpg?v=1713866710&width=1080",
      "https://wellgard.co.uk/cdn/shop/files/collagen-product-pictures-06_2a16f67e-f796-473d-b7e0-86d549e89af7.jpg?v=1713866710&width=1080",
      "https://wellgard.co.uk/cdn/shop/products/collagen-supplement-label-nutrition.jpg?v=1713866710&width=1080",
    ],
  },
  {
    id: 2,
    name: "Beauty Gummies with Biotin & Zinc for Skin, Hair & Nails",
    price: "14.99",
    images: [
      "https://cdn.shopify.com/s/files/1/0244/9460/4362/files/beautygummies_400x400.jpg?v=1692611985",
    ],
  },
  {
    id: 3,
    name: "Gutstar 20 Billion CFU Probiotics For Gut Healths",
    price: "15.99",
    images: [
      "https://cdn.shopify.com/s/files/1/0244/9460/4362/files/gutstar-probiotics_400x400.png?v=1711366308",
    ],
  },
  {
    id: 4,
    name: "Gutstar 20 Billion CFU Probiotics For Gut Health",
    price: "14.99",
    images: [
      "https://cdn.shopify.com/s/files/1/0244/9460/4362/files/gutstar-probiotics_400x400.png?v=1711366308",
    ],
  },
  {
    id: 5,
    name: "Vegan Apple Cider Vinegar Gummies",
    price: "18.99",
    images: [
      "https://cdn.shopify.com/s/files/1/0244/9460/4362/products/apple-cider-new-package-copy2_400x400.jpg?v=1676562975",
    ],
  },
  {
    id: 6,
    name: "Gutstar 20 Billion CFU Probiotics For Gut Health",
    price: "14.99",
    images: [
      "https://cdn.shopify.com/s/files/1/0244/9460/4362/files/gutstar-probiotics_400x400.png?v=1711366308",
    ],
  },
  {
    id: 7,
    name: "Vegan Apple Cider Vinegar Gummies",
    price: "18.99",
    images: [
      "https://cdn.shopify.com/s/files/1/0244/9460/4362/products/apple-cider-new-package-copy2_400x400.jpg?v=1676562975",
    ],
  },
  {
    id: 8,
    name: "Fortified Kombucha",
    price: "18.99",
    prePrice: "24.99",
    images: [
      "https://cdn.shopify.com/s/files/1/0244/9460/4362/files/fortified-kombucha-apple-cider-vinegar_400x400.png?v=1734128236",
    ],
  },
  {
    id: 9,
    name: "My Journey High Strength Multivitamins & Minerals",
    price: "24.99",
    images: [
      "https://cdn.shopify.com/s/files/1/0244/9460/4362/files/my-journey_0000_Layer-1_400x400.png?v=1731535977",
    ],
  },
  {
    id: 10,
    name: "Mag 1200 Magnesium Glycinate",
    price: "9.99",
    prePrice: "16.99",
    images: [
      "https://cdn.shopify.com/s/files/1/0244/9460/4362/files/mag1200_400x400.jpg?v=1708348559",
    ],
  },
  {
    id: 11,
    name: "Ruby Powder",
    price: "24.99",
    images: [
      "https://cdn.shopify.com/s/files/1/0244/9460/4362/files/rub3d-clean_400x400.jpg?v=1727939109",
    ],
  },
  {
    id: 12,
    name: "Vegan Biotin & Iron Gummies",
    price: "9.99",
    prePrice: "13.99",
    images: [
      "https://cdn.shopify.com/s/files/1/0244/9460/4362/files/resized-website-3d-pictures_c9d5a0b8-7815-4b43-a03f-aac3c2ef985b_400x400.jpg?v=1700059002",
    ],
  },
  {
    id: 13,
    name: "Micro Greens",
    price: "18.99",
    prePrice: "29.99",
    images: [
      "https://cdn.shopify.com/s/files/1/0244/9460/4362/files/super-greens-micro-greens-_0001_super-greens-micro-greens_400x400.png?v=1721592637",
    ],
  },
  {
    id: 14,
    name: "Ultimate Vegan Glucosamine+",
    price: "16.99",
    images: [
      "https://cdn.shopify.com/s/files/1/0244/9460/4362/products/wellgard_0005_vegan-glucosamine-and-chondroiton_400x400.png?v=1659428983",
    ],
  },
  {
    id: 15,
    name: "Ultimate Vegan Multivitamins & Minerals",
    price: "11.99",
    images: [
      "https://cdn.shopify.com/s/files/1/0244/9460/4362/products/wellgard_0003_vegan-multivitamin-tablets_400x400.png?v=1659429659",
    ],
  },
  {
    id: 16,
    name: "Ultimate Vegan Calcium & Magnesium",
    price: "13.99",
    images: [
      "https://cdn.shopify.com/s/files/1/0244/9460/4362/products/wellgard_0006_calcium-and-magnesium-tablets_400x400.png?v=1659429102",
    ],
  },
  {
    id: 17,
    name: "Organic Chaga Mushroom Powder",
    price: "19.99",
    images: [
      "https://cdn.shopify.com/s/files/1/0244/9460/4362/files/chaga3_400x400.jpg?v=1697461616",
    ],
  },
];

export default productData;
