import React, { useState, useContext, useRef } from "react";
import "./style/story.scss";
import productData from "../../Datas/productData";
import { CartContext } from "../CartContext";
import { useNavigate } from "react-router-dom";
import reviewsData from "../../Datas/reviewsData";
import questionData from "../../Datas/questionData";

function Story() {
  const [openSections, setOpenSections] = useState({});
  const navigate = useNavigate();
  const { addToCart } = useContext(CartContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [successMessage, setSuccessMessage] = useState(null);
  const indexSectionRef = useRef(null);
  const [qesName, setQesName] = useState("");
  const [qesEmail, setQesEmail] = useState("");
  const [qes, setQes] = useState("");
  const [qesSuccessMessage, setQesSuccessMessage] = useState(null);
  const [emailIndex, setEmailIndex] = useState(true);
  const qesIndexSectionRef = useRef(null);
  const [likeCounts, setLikeCounts] = useState(
    reviewsData.map((review) => review.like)
  );

  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

  const handleLikeClick = (index) => {
    setLikeCounts((prevCounts) => {
      const newCounts = [...prevCounts];
      newCounts[index] = newCounts[index] + 1;
      return newCounts;
    });
  };

  const handleToggle = (id) => {
    setOpenSections((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleAddToCart = (id) => {
    addToCart(id, 1);

    navigate("/cartpage");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handlReviews = (e) => {
    e.preventDefault();
    if (!name || !title || !body) {
      setSuccessMessage(false);
      return;
    }
    setName("");
    setEmail("");
    setTitle("");
    setBody("");
    setSuccessMessage(true);
    if (indexSectionRef.current) {
      indexSectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      window.scrollBy(0, -30);
    }
  };
  const handlQes = (e) => {
    e.preventDefault();

    if (!qesName || !qesEmail || !qes) {
      setQesSuccessMessage(false);
      return;
    } else if (qesEmail) {
      if (!emailRegex.test(qesEmail)) {
        setEmailIndex(false);
        return;
      }
    }
    setQesName("");
    setQesEmail("");
    setQes("");
    setQesSuccessMessage(true);
    if (qesIndexSectionRef.current) {
      qesIndexSectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      window.scrollBy(0, -30);
    }
  };
  return (
    <div className="story_box">
      <div className="shopify-section index-section">
        <div className="text-center page-width page-width--narrow">
          <div className="theme-block">
            <h2>Anything else is just an expensive placebo</h2>
          </div>
          <div className="theme-block">
            <div className="rte">
              <div className="enlarge-text">
                <p>
                  There are lots of collagen brands. And then there's Wellgard
                  collagen powder. The truth is, most alternatives in the market
                  cost you at least £40 for 400g of *not that pure* collagen.
                  With Wellgard you pay as little as £16.99 for 400g of pure
                  collagen. 100% free of hormones, antibiotics or additives with
                  wonky stinky names.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="shopify-section index-section">
        <div className="page-width">
          <div className="shopify_grid">
            <div
              className="col-mid-4 col-big-4 col-sm-12 col-xs-12 grid__item medium-up--one-third text-center aos-init aos-animate"
              data-aos="row-of-3"
            >
              <h3>
                Goes well with big, sassy refreshing margaritas. Also fabulous
                without
              </h3>
              <div className="rte-setting text-spacing">
                <p>
                  Our collagen powder is surprisingly versatile. To begin with,
                  it's hydrolysed. This means you're getting the purest form of
                  collagen. In fact, it's so pure and tastes so neutral you can
                  add it to just about any drink. Even Margaritas. And rumour
                  has it that it's fabulous for baking.
                </p>
              </div>
            </div>
            <div
              className="col-mid-4 col-big-4 col-sm-12 col-xs-12 grid__item medium-up--one-third text-center aos-init aos-animate"
              data-aos="row-of-3"
            >
              <h3>
                It's the perfect excuse to control at least 1 thing in life:
                Your sanity
              </h3>
              <div className="rte-setting text-spacing">
                <p>
                  You see, our collagen powder is made using highly bioactive
                  collagen peptides. And it contains 18 amino acids. Including 8
                  out of 9 essential amino acids your body needs (and can't
                  produce naturally). Like histidine, isoleucine or leucine. So
                  you can start living a more joyful, balanced life.
                </p>
              </div>
            </div>
            <div
              className="col-mid-4 col-big-4 col-sm-12 col-xs-12 grid__item medium-up--one-third text-center aos-init aos-animate"
              data-aos="row-of-3"
            >
              <h3>
                In 12 weeks you'll shout, "God, I feel more alive than ever"
              </h3>
              <div className="rte-setting text-spacing">
                <p>
                  Hack your favorite morning drink with 13g of Wellgard collagen
                  powder sourced from happy cows. This will stimulate collagen
                  in your hair, skin, nails and body. But it will take you 12
                  weeks to start seeing real results. Because nothing good
                  happens fast.&nbsp;
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="shopify-section index-section">
        <div className="page-width">
          <div className="shopify_grid shop_grid_next">
            <div
              className="page_right col-mid-5 col-big-6 col-sm-12 col-xs-12 grid__item medium-up--one-third text-center aos-init aos-animate"
              data-aos="row-of-3"
            >
              <h3>💪🏼 Looking for a collagen boost?</h3>
              <div className="rte-setting text-spacing">
                <p>
                  Look no further, Wellgard collagen powder is here to save the
                  day! 💪🏼 Not only is it easily digestible, but it's also super
                  absorbable. Think of it like a superhero for your skin, hair,
                  and nails. 🦸🏻‍♀️ And let's be real, who doesn't want to feel like
                  a superhero?
                  <br />
                </p>
                <p>
                  But don't just take our word for it, thousands of people have
                  already fallen in love with our mighty good collagen powder.
                  💕 And trust us, it works. So go ahead, be a daredevil and try
                  it for yourself. Take 13 grams a day to enjoy the benefits of
                  Type I and III collagen. 💪🏼💕
                </p>
              </div>
            </div>

            <div className="col-mid-6 col-big-6 col-sm-12 col-xs-12 feature-row__item feature-row__images aos-init aos-animate">
              <img
                alt=""
                src="https://wellgard.co.uk/cdn/shop/files/collagen-01_dbc977d5-ae45-43be-8a6b-76743f57fd3f.jpg?v=1700045248&width=540"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="shopify-section index-section">
        <div className="page-width">
          <div className="shopify_grid shop_grid_next">
            <div
              className="page_right  col-mid-5 col-big-6 col-sm-12 col-xs-12 grid__item medium-up--one-third text-center aos-init aos-animate"
              data-aos="row-of-3"
            >
              <h3>
                🤔 Looking for a sneaky way to boost your collagen intake?
              </h3>
              <div className="rte-setting text-spacing">
                <p>
                  Well, have no fear, Wellgard collagen powder is here! 💪🏼 It's
                  so easy to mix into any food or drink, you'll be sipping and
                  munching your way to better skin, hair, and nails before you
                  know it. 🍵 Mix it in your morning coffee or tea, add it to
                  your smoothie or juice, or even dump it in plain old water.
                  It's unflavoured and virtually odorless, so you won't even
                  know it's there. 🍲 But that's not all, it's versatile as a
                  ninja, you can mix it in your soup, porridge, cereal, or any
                  other food you can think of, the possibilities are endless. So
                  go ahead, be a secret agent of beauty and give it a try! 🕵️‍♀️
                </p>
              </div>
            </div>

            <div className="col-mid-6 col-big-6 col-sm-12 col-xs-12 feature-row__item feature-row__images aos-init aos-animate">
              <img
                alt=""
                src="https://wellgard.co.uk/cdn/shop/files/collagen-02.jpg?v=1700045247&width=1080"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="shopify-section index-section">
        <div className="page-width">
          <div className="shopify_grid shop_grid_next">
            <div
              className="page_right col-mid-5 col-big-6 col-sm-12 col-xs-12 grid__item medium-up--one-third text-center aos-init aos-animate"
              data-aos="row-of-3"
            >
              <h3>💪🏼 Want to give your body a little extra TLC?</h3>
              <div className="rte-setting text-spacing">
                <p>
                  Well, have no fear, Wellgard collagen powder is here! 💪🏼
                  Collagen is like the glue that holds your body together, and
                  our powder is packed with high levels of the 8 essential amino
                  acids that your body needs to keep everything in tip-top
                  shape.
                  <br />
                </p>
                <p>
                  🧬Our scientifically-tested collagen powder contains small
                  bioavailable collagen peptides that are easily absorbed by the
                  body, so you can feel the benefits in no time. 💪🏼 So go ahead,
                  give your body the love and care it deserves with a daily dose
                  of our collagen powder.
                </p>
              </div>
            </div>

            <div className="col-mid-6 col-big-6 col-sm-12 col-xs-12 feature-row__item feature-row__images aos-init aos-animate">
              <img
                alt=""
                src="https://wellgard.co.uk/cdn/shop/files/collagen-03.jpg?v=1700045246&width=1080"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="shopify-section index-section">
        <div className="page-width">
          <div className="shopify_grid shop_grid_next">
            <div
              className="page_right col-mid-5 col-big-6 col-sm-12 col-xs-12 grid__item medium-up--one-third text-center aos-init aos-animate"
              data-aos="row-of-3"
            >
              <h3>🍹 Want to add some oomph to your drink game?</h3>
              <div className="rte-setting text-spacing">
                <p>
                  Look no further, Wellgard collagen powder is here to save the
                  day!🍹 With its low molecular weight, it's optimized for
                  maximum solubility in both hot and cold drinks. So, say
                  goodbye to lumpy collagen drinks, and hello to smooth
                  sipping.🍹No more clumps, no more residue, just a delicious
                  and nutritious drink. 🍵 And let's be real, who wants a clumpy
                  drink?
                  <br />
                </p>
                <p>So go ahead, be a mix master and give it a try!🍸</p>
              </div>
            </div>

            <div className="col-mid-6 col-big-6 col-sm-12 col-xs-12 feature-row__item feature-row__images aos-init aos-animate">
              <img
                alt=""
                src="https://wellgard.co.uk/cdn/shop/files/collagen-04.jpg?v=1700045247&width=1080"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="shopify-section index-section">
        <div className="page-width">
          <div className="shopify_grid shop_grid_next">
            <div
              className="page_right col-mid-5 col-big-6 col-sm-12 col-xs-12 grid__item medium-up--one-third text-center aos-init aos-animate"
              data-aos="row-of-3"
            >
              <h3>💪🏼 Tired of unwanted additives?</h3>
              <div className="rte-setting text-spacing">
                <p>
                  Well, have no fear, Wellgard collagen powder is here to save
                  the day! 💪🏼
                  <br />
                </p>
                <p>
                  Our collagen powder is as pure as can be, free from hormones,
                  gluten, wheat, yeast, milk, lactose, soy, artificial flavours,
                  artificial sweeteners, and preservatives. 💪🏼 And the best
                  part? It's packed with more than 97% protein (on a dry weight
                  basis) and its hydrolysed form makes it easy to digest. So,
                  you can feel good about what you're putting in your body. 🌟
                  So go ahead, be a purist and give it a try!
                </p>
              </div>
            </div>

            <div className="col-mid-6 col-big-6 col-sm-12 col-xs-12 feature-row__item feature-row__images aos-init aos-animate">
              <img
                alt=""
                src="https://wellgard.co.uk/cdn/shop/files/collagen-05_de04db9a-48e2-4c5e-adab-95b75156d1c8.jpg?v=1700578721&width=1080"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="shopify-section index-section">
        <div className="page-width page-width--narrow">
          <header className="section-header">
            <h2 className="section-header__title">
              You’ve got questions. And so and so and so… we've got answers.
            </h2>
          </header>
          <div>
            <button
              type="button"
              className="collapsible-trigger collapsible-trigger--inline collapsible--auto-height text-left"
              aria-controls="Product-content-5d3678f4-f732-4c61-8d2c-7e0f0d4730713670667067466"
              aria-expanded="false"
              onClick={() => handleToggle("section-1")}
            >
              <span
                className="collapsible-trigger__icon collapsible-trigger__icon--open"
                role="presentation"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="presentation"
                  className={`icon ${
                    openSections["section-1"] ? "icon-minus" : "icon-plus"
                  }`}
                  viewBox="0 0 20 20"
                >
                  {openSections["section-1"] ? (
                    <path
                      fill="#444"
                      d="M17.543 11.029H2.1A1.032 1.032 0 0 1 1.071 10c0-.566.463-1.029 1.029-1.029h15.443c.566 0 1.029.463 1.029 1.029 0 .566-.463 1.029-1.029 1.029z"
                    />
                  ) : (
                    <path
                      fill="#444"
                      d="M17.409 8.929h-6.695V2.258c0-.566-.506-1.029-1.071-1.029s-1.071.463-1.071 1.029v6.671H1.967C1.401 8.929.938 9.435.938 10s.463 1.071 1.029 1.071h6.605V17.7c0 .566.506 1.029 1.071 1.029s1.071-.463 1.071-1.029v-6.629h6.695c.566 0 1.029-.506 1.029-1.071s-.463-1.071-1.029-1.071z"
                    />
                  )}
                </svg>
              </span>
              <span>What exactly is collagen?</span>
            </button>

            <div
              id="FAQ-content-16361975746957ee1a-0"
              className={`collapsible-content collapsible-content--all  ${
                openSections["section-1"] ? "visible is-open" : "hidden"
              }`}
            >
              <div className="collapsible-content__inner collapsible-content__inner--faq rte">
                <p>
                  30% of the protein in the human body is collagen. So think of
                  collagen as a natural <em>glue</em> that holds your body
                  together. You need it for hair, tendons, bones, and skin
                  health.{" "}
                </p>
                <p>
                  Now you might say with some justification that you don't need
                  collagen supplements because your body makes its own
                  collagen.&nbsp;
                </p>
                <p>
                  It's true, your body makes collagen naturally. But as you get
                  older, your body produces less collagen. That's why you need
                  Wellgard Collagen Powder.
                </p>
              </div>
            </div>
          </div>
          <div>
            <button
              type="button"
              className="collapsible-trigger collapsible-trigger--inline collapsible--auto-height text-left"
              aria-controls="Product-content-5d3678f4-f732-4c61-8d2c-7e0f0d4730713670667067466"
              aria-expanded="false"
              onClick={() => handleToggle("section-2")}
            >
              <span
                className="collapsible-trigger__icon collapsible-trigger__icon--open"
                role="presentation"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="presentation"
                  className={`icon ${
                    openSections["section-2"] ? "icon-minus" : "icon-plus"
                  }`}
                  viewBox="0 0 20 20"
                >
                  {openSections["section-2"] ? (
                    <path
                      fill="#444"
                      d="M17.543 11.029H2.1A1.032 1.032 0 0 1 1.071 10c0-.566.463-1.029 1.029-1.029h15.443c.566 0 1.029.463 1.029 1.029 0 .566-.463 1.029-1.029 1.029z"
                    />
                  ) : (
                    <path
                      fill="#444"
                      d="M17.409 8.929h-6.695V2.258c0-.566-.506-1.029-1.071-1.029s-1.071.463-1.071 1.029v6.671H1.967C1.401 8.929.938 9.435.938 10s.463 1.071 1.029 1.071h6.605V17.7c0 .566.506 1.029 1.071 1.029s1.071-.463 1.071-1.029v-6.629h6.695c.566 0 1.029-.506 1.029-1.071s-.463-1.071-1.029-1.071z"
                    />
                  )}
                </svg>
              </span>
              <span>Why do I need collagen supplement?</span>
            </button>

            <div
              id="FAQ-content-16361975746957ee1a-0"
              className={`collapsible-content collapsible-content--all  ${
                openSections["section-2"] ? "visible is-open" : "hidden"
              }`}
            >
              <div className="collapsible-content__inner collapsible-content__inner--faq rte">
                <p>
                  Amino acids are the building blocks of our body and enable us
                  to heal, grow, repair and rejuvenate. They are also essential
                  for our bodies to be able to produce collagen. However,
                  production peaks around the age of 20, slowing down as we age.
                  This reduction causes the gradual deterioration
                  of&nbsp;protein&nbsp;fibres which results in our skin becoming
                  dehydrated and thinner and causes wrinkles to appear. It also
                  leads to the loss of bone and cartilage plus increased pain,
                  discomfort and inflammation in our joints. Our hair can start
                  to thin, our hormones go awry, our bodies start to creak and
                  ache, our digestion suffers and we simply don’t feel as well
                  as we could. Our&nbsp;products contain&nbsp;18 amino acids
                  including eight out of nine essential amino acids which the
                  body needs.
                </p>
              </div>
            </div>
          </div>{" "}
          <div>
            <button
              type="button"
              className="collapsible-trigger collapsible-trigger--inline collapsible--auto-height text-left"
              aria-controls="Product-content-5d3678f4-f732-4c61-8d2c-7e0f0d4730713670667067466"
              aria-expanded="false"
              onClick={() => handleToggle("section-3")}
            >
              <span
                className="collapsible-trigger__icon collapsible-trigger__icon--open"
                role="presentation"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="presentation"
                  className={`icon ${
                    openSections["section-3"] ? "icon-minus" : "icon-plus"
                  }`}
                  viewBox="0 0 20 20"
                >
                  {openSections["section-3"] ? (
                    <path
                      fill="#444"
                      d="M17.543 11.029H2.1A1.032 1.032 0 0 1 1.071 10c0-.566.463-1.029 1.029-1.029h15.443c.566 0 1.029.463 1.029 1.029 0 .566-.463 1.029-1.029 1.029z"
                    />
                  ) : (
                    <path
                      fill="#444"
                      d="M17.409 8.929h-6.695V2.258c0-.566-.506-1.029-1.071-1.029s-1.071.463-1.071 1.029v6.671H1.967C1.401 8.929.938 9.435.938 10s.463 1.071 1.029 1.071h6.605V17.7c0 .566.506 1.029 1.071 1.029s1.071-.463 1.071-1.029v-6.629h6.695c.566 0 1.029-.506 1.029-1.071s-.463-1.071-1.029-1.071z"
                    />
                  )}
                </svg>
              </span>
              <span>
                How many mg of bovine collage is there in a 13g portion please?
              </span>
            </button>

            <div
              id="FAQ-content-16361975746957ee1a-0"
              className={`collapsible-content collapsible-content--all  ${
                openSections["section-3"] ? "visible is-open" : "hidden"
              }`}
            >
              <div className="collapsible-content__inner collapsible-content__inner--faq rte">
                <p>
                  Each daily serving of Wellgard Collagen Powder contains
                  13,000mg of pure collagen. We do not add any additives,
                  flavouring, preservative or bulking agents in the product.
                  What you get is pure collagen goodness free from all nasties
                  in including fat, sugar, carbs and cholesterol.
                </p>
              </div>
            </div>
          </div>
          <div>
            <button
              type="button"
              className="collapsible-trigger collapsible-trigger--inline collapsible--auto-height text-left"
              aria-controls="Product-content-5d3678f4-f732-4c61-8d2c-7e0f0d4730713670667067466"
              aria-expanded="false"
              onClick={() => handleToggle("section-4")}
            >
              <span
                className="collapsible-trigger__icon collapsible-trigger__icon--open"
                role="presentation"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="presentation"
                  className={`icon ${
                    openSections["section-4"] ? "icon-minus" : "icon-plus"
                  }`}
                  viewBox="0 0 20 20"
                >
                  {openSections["section-4"] ? (
                    <path
                      fill="#444"
                      d="M17.543 11.029H2.1A1.032 1.032 0 0 1 1.071 10c0-.566.463-1.029 1.029-1.029h15.443c.566 0 1.029.463 1.029 1.029 0 .566-.463 1.029-1.029 1.029z"
                    />
                  ) : (
                    <path
                      fill="#444"
                      d="M17.409 8.929h-6.695V2.258c0-.566-.506-1.029-1.071-1.029s-1.071.463-1.071 1.029v6.671H1.967C1.401 8.929.938 9.435.938 10s.463 1.071 1.029 1.071h6.605V17.7c0 .566.506 1.029 1.071 1.029s1.071-.463 1.071-1.029v-6.629h6.695c.566 0 1.029-.506 1.029-1.071s-.463-1.071-1.029-1.071z"
                    />
                  )}
                </svg>
              </span>
              <span>
                Is Wellgard Collagen Powder free from antibiotics and hormones?
              </span>
            </button>

            <div
              id="FAQ-content-16361975746957ee1a-0"
              className={`collapsible-content collapsible-content--all  ${
                openSections["section-4"] ? "visible is-open" : "hidden"
              }`}
            >
              <div className="collapsible-content__inner collapsible-content__inner--faq rte">
                <p>
                  Yes, Wellgard Collagen Powder is free from antibiotics and
                  hormones. Every single batch of the product is certified
                  accordingly.{" "}
                </p>
              </div>
            </div>
          </div>{" "}
          <div>
            <button
              type="button"
              className="collapsible-trigger collapsible-trigger--inline collapsible--auto-height text-left"
              aria-controls="Product-content-5d3678f4-f732-4c61-8d2c-7e0f0d4730713670667067466"
              aria-expanded="false"
              onClick={() => handleToggle("section-5")}
            >
              <span
                className="collapsible-trigger__icon collapsible-trigger__icon--open"
                role="presentation"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="presentation"
                  className={`icon ${
                    openSections["section-5"] ? "icon-minus" : "icon-plus"
                  }`}
                  viewBox="0 0 20 20"
                >
                  {openSections["section-5"] ? (
                    <path
                      fill="#444"
                      d="M17.543 11.029H2.1A1.032 1.032 0 0 1 1.071 10c0-.566.463-1.029 1.029-1.029h15.443c.566 0 1.029.463 1.029 1.029 0 .566-.463 1.029-1.029 1.029z"
                    />
                  ) : (
                    <path
                      fill="#444"
                      d="M17.409 8.929h-6.695V2.258c0-.566-.506-1.029-1.071-1.029s-1.071.463-1.071 1.029v6.671H1.967C1.401 8.929.938 9.435.938 10s.463 1.071 1.029 1.071h6.605V17.7c0 .566.506 1.029 1.071 1.029s1.071-.463 1.071-1.029v-6.629h6.695c.566 0 1.029-.506 1.029-1.071s-.463-1.071-1.029-1.071z"
                    />
                  )}
                </svg>
              </span>
              <span>
                What is the molecular weight of Wellgard Collagen Powder in
                daltons?
              </span>
            </button>

            <div
              id="FAQ-content-16361975746957ee1a-0"
              className={`collapsible-content collapsible-content--all  ${
                openSections["section-5"] ? "visible is-open" : "hidden"
              }`}
            >
              <div class="collapsible-content__inner collapsible-content__inner--faq rte">
                <p>
                  Wellgard Collagen Powerder's average molecular weight is 2 kDa
                  (2000 Daltons), this is optimum bioavailability and
                  unparralled on the market compared to other bovine collagen
                  supplements.{" "}
                </p>
              </div>
            </div>
          </div>{" "}
          <div>
            <button
              type="button"
              className="collapsible-trigger collapsible-trigger--inline collapsible--auto-height text-left"
              aria-controls="Product-content-5d3678f4-f732-4c61-8d2c-7e0f0d4730713670667067466"
              aria-expanded="false"
              onClick={() => handleToggle("section-6")}
            >
              <span
                className="collapsible-trigger__icon collapsible-trigger__icon--open"
                role="presentation"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="presentation"
                  className={`icon ${
                    openSections["section-6"] ? "icon-minus" : "icon-plus"
                  }`}
                  viewBox="0 0 20 20"
                >
                  {openSections["section-6"] ? (
                    <path
                      fill="#444"
                      d="M17.543 11.029H2.1A1.032 1.032 0 0 1 1.071 10c0-.566.463-1.029 1.029-1.029h15.443c.566 0 1.029.463 1.029 1.029 0 .566-.463 1.029-1.029 1.029z"
                    />
                  ) : (
                    <path
                      fill="#444"
                      d="M17.409 8.929h-6.695V2.258c0-.566-.506-1.029-1.071-1.029s-1.071.463-1.071 1.029v6.671H1.967C1.401 8.929.938 9.435.938 10s.463 1.071 1.029 1.071h6.605V17.7c0 .566.506 1.029 1.071 1.029s1.071-.463 1.071-1.029v-6.629h6.695c.566 0 1.029-.506 1.029-1.071s-.463-1.071-1.029-1.071z"
                    />
                  )}
                </svg>
              </span>
              <span>How many servings are there per 400g container?</span>
            </button>

            <div
              id="FAQ-content-16361975746957ee1a-0"
              className={`collapsible-content collapsible-content--all  ${
                openSections["section-6"] ? "visible is-open" : "hidden"
              }`}
            >
              <div className="collapsible-content__inner collapsible-content__inner--faq rte">
                <p>
                  Each tub contains 400g of collagen which equates to 31
                  servings of 2 tablespoons each.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row_three">
        <h4 class="primary-title">
          <h2>You might also like</h2>
        </h4>
        <div className="rebuy_product_grid">
          {productData
            .filter((e) => e.id >= 10 && e.id <= 13)
            .map((product, index) => (
              <div
                className="rebuy-product-block col-big-3 col-mid-3 col-sm-6 col-xs-6"
                key={index}
              >
                <div className="rebuy-product-media">
                  <img
                    loading="eager"
                    src={product.images[0]}
                    alt={product.name}
                  />
                </div>

                <div className="rebuy-product-info">
                  <div className="rebuy-product-title clickable">
                    {product.name}
                  </div>
                  <div className="rebuy-product-price">
                    <div>
                      <span
                        className={`rebuy-money ${
                          product.prePrice ? "money_red" : ""
                        }`}
                      >
                        <span tabIndex={0}>£{product.price}</span>
                      </span>
                      {product.prePrice && (
                        <span class="rebuy-money compare-at">
                          <span>£{product.prePrice}</span>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="rebuy-product-actions">
                  <button
                    aria-label="Add the product, Gutstar 20 Billion CFU Probiotics For Gut Health to Cart"
                    type="button"
                    className="rebuy-button"
                    onClick={() => handleAddToCart(product.id)}
                  >
                    <span>
                      <b>Add to Cart</b>
                    </span>
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className="page-width" style={{ padding: "0 20px" }}>
        <div className="reviews-importify-element ng-scope">
          <div
            id="reviews-importify-reviews"
            className="reviews-importify-reviews reviews-importify-reviews-3670667067466"
          >
            <div className="reviews-importify-overview  col-big-12 col-mid-12 col-sm-12 col-sx-12">
              <h3 class="reviews_overview_heading">Customer Reviews</h3>
            </div>

            <div className="review-overview-row-col1  col-big-4 col-mid-12 col-sm-12 col-sx-12">
              <div className="reviews-importify-rating-stars">
                <img alt="" src="/head/star.png" />
              </div>
              <b className="ri_total_reviews">4.8 / 5</b>
              <p className="average_rating_text">
                <span>Based on 3631 reviews</span>
              </p>
            </div>

            <div className="review-overview-row-col2  col-big-4 col-mid-12 col-sm-12 col-sx-12">
              <div className="review_rating_bars">
                <div
                  className="review_bar five_star_bar"
                  ng-click="filterReviewsByRating('five_star')"
                >
                  <div className="bar_star">5 Stars</div>
                  <div className="bar_line">
                    <div className="bar_line_container">
                      <div
                        className="bar_line_filled"
                        style={{ width: "85%" }}
                      />
                    </div>
                  </div>
                  <div className="bar_percentage">85% (3087)</div>
                  <div className="clearfloat" />
                </div>
                <div
                  className="review_bar four_star_bar"
                  ng-click="filterReviewsByRating('four_star')"
                >
                  <div className="bar_star">4 Stars</div>
                  <div className="bar_line">
                    <div className="bar_line_container">
                      <div
                        className="bar_line_filled"
                        style={{ width: "10%" }}
                      />
                    </div>
                  </div>
                  <div className="bar_percentage">10% (346)</div>
                  <div className="clearfloat" />
                </div>
                <div
                  className="review_bar three_star_bar"
                  ng-click="filterReviewsByRating('three_star')"
                >
                  <div className="bar_star">3 Stars</div>
                  <div className="bar_line">
                    <div className="bar_line_container">
                      <div
                        className="bar_line_filled"
                        style={{ width: "3%" }}
                      />
                    </div>
                  </div>
                  <div className="bar_percentage">3% (112)</div>
                  <div className="clearfloat" />
                </div>
                <div
                  className="review_bar two_star_bar"
                  ng-click="filterReviewsByRating('two_star')"
                >
                  <div className="bar_star">2 Stars</div>
                  <div className="bar_line">
                    <div className="bar_line_container">
                      <div
                        className="bar_line_filled"
                        style={{ width: "1%" }}
                      />
                    </div>
                  </div>
                  <div className="bar_percentage">1% (31)</div>
                  <div className="clearfloat" />
                </div>
                <div
                  className="review_bar one_star_bar"
                  ng-click="filterReviewsByRating('one_star')"
                >
                  <div className="bar_star">1 Star</div>
                  <div className="bar_line">
                    <div className="bar_line_container">
                      <div
                        className="bar_line_filled"
                        style={{ width: "2%" }}
                      />
                    </div>
                  </div>
                  <div className="bar_percentage">2% (55)</div>
                  <div className="clearfloat" />
                </div>
              </div>
            </div>

            <div className="review-overview-row-col3 col-big-4 col-mid-12 col-sm-12 col-sx-12">
              <button
                type="button"
                ng-click="showWriteReviewForm()"
                id="importify-reviews-write-review"
                className="importify-reviews-btn"
                onClick={() => handleToggle("section-7")}
              >
                Write Review
              </button>
            </div>

            <div className="clearfloat height_10 col-big-12 col-mid-12 col-sm-12 col-sx-12" />
            <div
              className={`reviews_importify_write_review_form_container  col-big-12 col-mid-12 col-sm-12 col-sx-12  ${
                openSections["section-7"] ? "visible is-open" : "hidden"
              }`}
            >
              <form>
                <fieldset ref={indexSectionRef}>
                  <legend>
                    <h3>Write a Review</h3>
                  </legend>
                  <p className="validateTips" />
                  {successMessage === false && (
                    <p
                      className="riWriteReviewErrors"
                      style={{
                        padding: 5,
                        background: "red",
                        color: "rgb(255, 255, 255)",
                      }}
                    >
                      Name is Required
                      <br />
                      Review Title is Required
                      <br />
                      Review Body is Required
                      <br />
                    </p>
                  )}
                  {successMessage === true && (
                    <p
                      className="riWriteReviewSuccess"
                      style={{
                        padding: 5,
                        background: "green",
                        color: "rgb(255, 255, 255)",
                      }}
                    >
                      Your review is submitted successfully.
                    </p>
                  )}

                  <div className="ri-field-row">
                    <label htmlFor="">How would you rate this product?</label>
                    <select
                      name="rating"
                      className="ri_review_select_element ri_review_form_element"
                    >
                      <option value={5}>5 Stars</option>
                      <option value={4}>4 Stars</option>
                      <option value={3}>3 Stars</option>
                      <option value={2}>2 Stars</option>
                      <option value={1}>1 Star</option>
                    </select>
                  </div>
                  <div className="ri-field-row">
                    <label htmlFor="ri_review_reviewer_name">Your Name</label>
                    <input
                      type="text"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      id="ri_wreview_reviewer_name"
                      className="ri_review_form_element"
                    />
                  </div>
                  <div className="ri-field-row">
                    <label htmlFor="ri_review_reviewer_email">Your Email</label>
                    <input
                      type="text"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      id="ri_wreview_reviewer_email"
                      className="ri_review_form_element"
                    />
                  </div>
                  <div className="ri-field-row">
                    <label htmlFor="ri_review_title">Review Title</label>
                    <input
                      type="text"
                      name="title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      id="ri_write_review_title"
                      className="ri_review_form_element"
                    />
                  </div>
                  <div className="ri-field-row">
                    <label htmlFor="ri_review_body">
                      Provide some detail about your experience
                    </label>
                    <textarea
                      name="body"
                      value={body}
                      onChange={(e) => setBody(e.target.value)}
                      id="ri_write_review_body"
                      className="ri_review_form_element"
                      defaultValue={""}
                    />
                  </div>
                  <button
                    type="button"
                    className="importify-reviews-btn importify_reviews_write_btn"
                    onClick={handlReviews}
                  >
                    Submit
                  </button>
                </fieldset>
              </form>
            </div>

            <div className="ri-reviews-filter-bar-container col-big-12 col-mid-12 col-sm-12 col-sx-12">
              <div className="reviews-importify-filter-bar">
                <form>
                  <span class="ri-filter-option ri-filter-heading">Filter</span>
                  <span class="ri-filter-sep">|</span>
                  <span className="ri-filter-option">
                    <select
                      ng-init="riStoreData.settings.reviews_default_sorting ? ri_filter_sort_by = riStoreData.settings.reviews_default_sorting : ri_filter_sort_by = 'recent'"
                      ng-model="ri_filter_sort_by"
                      ng-change="filterReviews()"
                      className="ri-filter-select-option ri_review_select_element ng-pristine ng-untouched ng-valid ng-not-empty"
                      name="ri_filter_sort_by"
                      id="ri_filter_sort_by"
                    >
                      <option value="recent" selected="selected">
                        Sort By : Recent
                      </option>
                      <option value="top_rated">Sort By : Top Rated</option>
                      <option value="images_first">
                        Sort By : Images First
                      </option>
                    </select>
                  </span>
                  <span class="ri-filter-sep">|</span>
                  <span className="ri-filter-option">
                    <select
                      ng-init="ri_filter_rating = 'all_stars'"
                      ng-model="ri_filter_rating"
                      ng-change="filterReviews()"
                      className="ri-filter-select-option ri_review_select_element ng-pristine ng-untouched ng-valid ng-not-empty"
                      name="ri_filter_rating"
                      id="ri_filter_rating"
                    >
                      <option value="all_stars" selected="selected">
                        All Stars
                      </option>
                      <option value="five_star">5 Stars Only</option>
                      <option value="four_star">4 Stars Only</option>
                      <option value="three_star">3 Stars Only</option>
                      <option value="two_star">2 Stars Only</option>
                      <option value="one_star">1 Star Only</option>
                      <option value="positive">All Positive</option>
                      <option value="critical">All Critical</option>
                    </select>
                  </span>
                  <span class="ri-filter-sep">|</span>
                  <span className="ri-filter-option">
                    <label class="ri-filter-label" for="ri_filter_media">
                      With Media
                    </label>
                  </span>
                </form>
              </div>
            </div>

            <div className="ri-reviews ng-scope col-big-12 col-mid-12 col-sm-12 col-xs-12">
              {reviewsData.map((review, index) => (
                <div className="ri-reviews-row ng-scope" key={index}>
                  <div className="ri-review-row-col-1 ri-review-row-col col-big-3 col-mid-4 col-sm-12 col-xs-12">
                    <span className="review-user-image">
                      <span className="review-user-letter circle ng-binding">
                        {review.bac}
                      </span>
                    </span>
                    <span className="review-user-name reviews-importify-tooltip ng-binding">
                      {review.name}

                      <svg
                        className="feather feather-check"
                        fill="none"
                        height={24}
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        viewBox="0 0 24 24"
                        width={24}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline points="20 6 9 17 4 12" />
                      </svg>
                    </span>
                  </div>
                  <div className="ri-review-row-col-2  col-big-7 col-mid-4 col-sm-12 col-xs-12">
                    <div className="review_title_rating_star">
                      {Array.from({ length: 5 }, (v, i) => i).map(
                        (_, index) => (
                          <span key={index} className="rating_star">
                            <svg
                              height="58px"
                              version="1.1"
                              viewBox="0 0 58 58"
                              width="58px"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                            >
                              <title />
                              <desc />
                              <defs />
                              <g
                                fill="none"
                                fillRule="evenodd"
                                id="Page-1"
                                stroke="none"
                                strokeWidth={1}
                              >
                                <g
                                  id="019---Star"
                                  transform="translate(-1.000000, 0.000000)"
                                >
                                  <path
                                    d="M31.7569,1.14435 L39.2006,16.94809 C39.4742047,17.5450605 40.0274966,17.9662669 40.67576,18.07109 L57.32037,20.60534 C58.0728338,20.7512497 58.6840769,21.2991656 58.9110909,22.0312558 C59.1381048,22.7633461 58.9440977,23.560962 58.4062,24.107 L46.36205,36.40845 C45.8969861,36.8906851 45.6879532,37.5647752 45.79858,38.22553 L48.64182,55.59553 C48.7969313,56.3422303 48.5093863,57.1116407 47.9025754,57.5735945 C47.2957646,58.0355484 46.4775729,58.1079148 45.7991,57.75964 L30.9117,49.55864 C30.3445605,49.2442297 29.6554395,49.2442297 29.0883,49.55864 L14.2009,57.75964 C13.5224271,58.1079148 12.7042354,58.0355484 12.0974246,57.5735945 C11.4906137,57.1116407 11.2030687,56.3422303 11.35818,55.59553 L14.20142,38.22553 C14.3120468,37.5647752 14.1030139,36.8906851 13.63795,36.40845 L1.5938,24.107 C1.05593046,23.5609597 0.861941478,22.7633618 1.08895299,22.0312898 C1.31596449,21.2992177 1.92718692,20.7513115 2.67963,20.60539 L19.32424,18.0711 C19.9725034,17.9662769 20.5257953,17.5450705 20.7994,16.9481 L28.2431,1.14435 C28.5505421,0.448721422 29.2394609,-5.16717968e-06 30,-5.16717968e-06 C30.7605391,-5.16717968e-06 31.4494579,0.448721422 31.7569,1.14435 Z"
                                    fill="#F6AB27"
                                    id="Shape"
                                  />
                                  <path
                                    d="M37.39,13.11 C32.5890747,15.6770414 28.15587,18.8791741 24.21,22.63 C20.0044812,26.6560517 16.436883,31.2993247 13.63,36.4 L1.59009,24.11 C1.05224467,23.5636351 0.858777828,22.7655877 1.086713,22.0335783 C1.31464817,21.3015689 1.92698179,20.7544339 2.67993,20.61 L19.32007,18.07 C19.967444,17.9624793 20.520694,17.5438036 20.80007,16.95 L28.24,1.14 C28.5507895,0.446404951 29.2399578,1.95277886e-05 30,1.95277886e-05 C30.7600422,1.95277886e-05 31.4492105,0.446404951 31.76,1.14 L37.39,13.11 Z"
                                    fill="#F4CD1E"
                                    id="Shape"
                                  />
                                </g>
                              </g>
                            </svg>
                          </span>
                        )
                      )}
                      <span className="ri_review_title ng-binding">
                        {review.title}
                      </span>
                    </div>
                    <div
                      className="ri_review_description ng-binding"
                      ng-bind-html="review.description | unsafe"
                    >
                      {review.des}
                    </div>

                    <div
                      className="ri_review_images ng-scope"
                      ng-if="review.images && review.images.length > 0"
                    >
                      {review.images.map((image, index) => (
                        <span
                          key={index}
                          className="ri_review_image reviews-importify-tooltip ng-scope"
                          ng-repeat="image in review.images track by $index"
                        >
                          <img
                            className="img-fluid ri_review_image-thumb"
                            ng-src="https://m.media-amazon.com/images/I/614fw-PvUTL._SY88.jpg"
                            alt="review_image_thumb"
                            loading="lazy"
                            src={image}
                          />
                        </span>
                      ))}
                    </div>

                    <div className="rireview-date">
                      <span className="review_date_text ng-binding">
                        <span className="fa fa-calendar review-date-icon">
                          <svg
                            height={12}
                            viewBox="0 0 24 24"
                            width={11.16}
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M21 20V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2zM9 18H7v-2h2v2zm0-4H7v-2h2v2zm4 4h-2v-2h2v2zm0-4h-2v-2h2v2zm4 4h-2v-2h2v2zm0-4h-2v-2h2v2zm2-5H5V7h14v2z" />
                          </svg>
                        </span>
                        {review.time}
                      </span>
                      <span
                        className="verified_text ng-scope"
                        ng-if="review.is_verified == 1 && riStoreData.settings.reviews_section_verified_text_status != 'hide'"
                      >
                        <span>Verified Purchase</span>
                      </span>
                    </div>
                  </div>

                  <div className="ri-review-row-col-3   col-big-2 col-mid-4 col-sm-12 col-xs-12">
                    <div className="ri_reviews_thumbs">
                      <span className="ri_review_thumbs_up reviews-importify-tooltip">
                        <span
                          onClick={() => handleLikeClick(index)}
                          className="fa fa-thumbs-up ri_review_thumbs_up_icon"
                        >
                          <svg
                            fill="none"
                            height={20}
                            viewBox="0 0 20 20"
                            width={20}
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2 10.5C2 9.67157 2.67157 9 3.5 9C4.32843 9 5 9.67157 5 10.5V16.5C5 17.3284 4.32843 18 3.5 18C2.67157 18 2 17.3284 2 16.5V10.5Z"
                              fill="#374151"
                            />
                            <path
                              d="M6 10.3333V15.7639C6 16.5215 6.428 17.214 7.10557 17.5528L7.15542 17.5777C7.71084 17.8554 8.32329 18 8.94427 18H14.3604C15.3138 18 16.1346 17.3271 16.3216 16.3922L17.5216 10.3922C17.7691 9.15465 16.8225 8 15.5604 8H12V4C12 2.89543 11.1046 2 10 2C9.44772 2 9 2.44772 9 3V3.66667C9 4.53215 8.71929 5.37428 8.2 6.06667L6.8 7.93333C6.28071 8.62572 6 9.46785 6 10.3333Z"
                              fill="#374151"
                            />
                          </svg>
                        </span>
                      </span>
                      <span className="">
                        (
                        <span className="ri_review_thumbs_up_count ng-binding">
                          {likeCounts[index]}
                        </span>
                        )
                      </span>
                      <span className="reviews-importify-tooltiptext">
                        Helpful
                      </span>
                    </div>
                  </div>
                </div>
              ))}
              <p className="ng-binding">showing reviews from 1 to 15</p>
            </div>
          </div>
        </div>

        <div className="reviews-importify-element ng-scope">
          <div
            id="reviews-importify-reviews"
            className="reviews-importify-reviews reviews-importify-reviews-3670667067466"
          >
            <div className="reviews-importify-overview  col-big-12 col-mid-12 col-sm-12 col-sx-12">
              <h3 class="reviews_overview_heading">Product Questions</h3>
            </div>

            <div className="review-overview-row-col1  col-big-4 col-mid-4 col-sm-12 col-sx-12">
              <p className="average_rating_text">
                <span>13 Questions Asked</span>
              </p>
            </div>

            <div className="review-overview-row-col3 col-big-4 col-mid-12 col-sm-12 col-sx-12">
              <button
                type="button"
                ng-click="showWriteReviewForm()"
                id="importify-reviews-write-review"
                className="importify-reviews-btn"
                onClick={() => handleToggle("section-8")}
              >
                Ask Question
              </button>
            </div>

            <div className="clearfloat height_10 col-big-12 col-mid-12 col-sm-12 col-sx-12" />
            <div
              className={`reviews_importify_write_review_form_container  col-big-12 col-mid-12 col-sm-12 col-sx-12  ${
                openSections["section-8"] ? "visible is-open" : "hidden"
              }`}
            >
              <form>
                <fieldset ref={qesIndexSectionRef}>
                  <legend>
                    <h3>Ask a Question</h3>
                  </legend>
                  <p className="validateTips" />
                  {qesSuccessMessage === false && (
                    <p
                      className="riAskQuestionErrors"
                      style={{
                        padding: 5,
                        background: "red",
                        color: "rgb(255, 255, 255)",
                      }}
                    >
                      Name is Required
                      <br />
                      Email is Required
                      <br />
                      Question Field is required
                      <br />
                    </p>
                  )}
                  {qesSuccessMessage === true && (
                    <p
                      className="riAskQuestionSuccess"
                      style={{
                        padding: 5,
                        background: "green",
                        color: "rgb(255, 255, 255)",
                        display: "block",
                      }}
                    >
                      Your question is submitted successfully.
                    </p>
                  )}
                  {emailIndex === false && (
                    <p
                      className="riAskQuestionErrors"
                      style={{
                        padding: 5,
                        background: "red",
                        color: "rgb(255, 255, 255)",
                      }}
                    >
                      Please Enter a valid Email
                      <br />
                    </p>
                  )}

                  <div className="ri-field-row">
                    <label htmlFor="ri_question_author_name">Your Name</label>
                    <input
                      type="text"
                      name="qesName"
                      value={qesName}
                      onChange={(e) => setQesName(e.target.value)}
                      id="ri_question_author_name"
                      className="ri_review_form_element"
                    />
                  </div>
                  <div className="ri-field-row">
                    <label htmlFor="ri_question_author_email">Your Email</label>
                    <input
                      type="text"
                      name="qesEmail"
                      value={qesEmail}
                      onChange={(e) => setQesEmail(e.target.value)}
                      id="ri_question_author_email"
                      className="ri_review_form_element"
                    />
                  </div>
                  <div className="ri-field-row">
                    <label htmlFor="ri_question_title">
                      Enter Your Question
                    </label>
                    <textarea
                      name="qes"
                      value={qes}
                      onChange={(e) => setQes(e.target.value)}
                      id="ri_question_title"
                      className="ri_review_form_element"
                      defaultValue={""}
                    />
                  </div>

                  <button
                    type="button"
                    className="importify-reviews-btn importify_reviews_write_btn"
                    onClick={handlQes}
                  >
                    Submit
                  </button>
                </fieldset>
              </form>
            </div>

            <div className="ri-reviews-filter-bar-container col-big-12 col-mid-12 col-sm-12 col-sx-12">
              <div className="reviews-importify-filter-bar">
                <form>
                  <span class="ri-filter-option ri-filter-heading">Filter</span>
                  <span class="ri-filter-sep">|</span>
                  <span className="ri-filter-option">
                    <select
                      ng-init="riStoreData.settings.questions_default_sorting ? ri_filter_sort_by = riStoreData.settings.questions_default_sorting : ri_filter_sort_by = 'recent'"
                      ng-model="ri_filter_sort_by"
                      ng-change="filterQuestions()"
                      className="ri-filter-select-option ri_review_select_element ng-pristine ng-valid ng-not-empty ng-touched"
                      name="ri_qfilter_sort_by"
                      id="ri_qfilter_sort_by"
                    >
                      <option value="recent" selected="selected">
                        Sort By : Recent
                      </option>
                      <option value="question">Sort By : Question</option>
                    </select>
                  </span>
                  <span class="ri-filter-sep">|</span>
                  <span className="ri-filter-option">
                    <input
                      type="text"
                      placeholder="Enter search query"
                      ng-model="ri_qfilter_squery"
                      ng-change="filterQuestions()"
                      className="ri-filter-select-option ri_review_input_element ng-pristine ng-untouched ng-valid ng-empty"
                      name="ri_qfilter_squery"
                      id="ri_qfilter_squery"
                    />
                  </span>
                  <span class="ri-filter-sep">|</span>
                  <span className="ri-filter-option">
                    <label class="ri-filter-label" for="ri_filter_media">
                      1/1
                    </label>
                  </span>
                </form>
              </div>
            </div>

            <div className="ri-reviews ng-scope col-big-12 col-mid-12 col-sm-12 col-xs-12">
              {questionData.map((qes, index) => (
                <div className="ri-reviews-row ng-scope" key={index}>
                  <div className="ri-review-row-col-1 ri-review-row-col col-big-3 col-mid-7 col-sm-12 col-xs-12">
                    <span className="review-user-image">
                      <span className="review-user-letter circle ng-binding">
                        {qes.bac}
                      </span>
                    </span>
                    <span className="review-user-name reviews-importify-tooltip ng-binding">
                      {qes.name}
                    </span>
                  </div>
                  <div className="ri-review-row-col-2   col-big-7 col-mid-7 col-sm-12 col-xs-12">
                    <div className="ri_question_title">
                      <span style={{ display: "block" }} className="ng-binding">
                        <b>Q : </b> {qes.qes}
                      </span>
                      <div className="rireview-date rireview-question-date">
                        <span className="ri_question_date_author ng-binding">
                          Asked on {qes.askTime}
                        </span>
                      </div>
                    </div>
                    <div className="ri_question_answer ng-binding">
                      <b>A : </b> {qes.answer}
                    </div>
                    <div className="rireview-date rireview-question-date">
                      <span className="ri_question_date_author">
                        <span className="ng-binding">By Wellgard Support</span>
                        <span className="ng-binding">On {qes.answerTime}</span>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
              <p className="ng-binding-p">showing reviews from 1 to 15</p>
            </div>
          </div>
        </div>

        <div className="row_three">
          <h4 class="primary-title">
            <h2>More to love</h2>
          </h4>
          <div className="rebuy_product_grid">
            {productData
              .filter((e) => e.id >= 14 && e.id <= 17)
              .map((product, index) => (
                <div
                  className="rebuy-product-block col-big-3 col-mid-3 col-sm-6 col-xs-6"
                  key={index}
                >
                  <div className="rebuy-product-media">
                    <img
                      loading="eager"
                      src={product.images[0]}
                      alt={product.name}
                    />
                  </div>

                  <div className="rebuy-product-info">
                    <div className="rebuy-product-title clickable">
                      {product.name}
                    </div>
                    <div className="rebuy-product-price">
                      <div>
                        <span
                          className={`rebuy-money ${
                            product.prePrice ? "money_red" : ""
                          }`}
                        >
                          <span tabIndex={0}>£{product.price}</span>
                        </span>
                        {product.prePrice && (
                          <span class="rebuy-money compare-at">
                            <span>£{product.prePrice}</span>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="rebuy-product-actions">
                    <button
                      aria-label="Add the product, Gutstar 20 Billion CFU Probiotics For Gut Health to Cart"
                      type="button"
                      className="rebuy-button"
                      onClick={() => handleAddToCart(product.id)}
                    >
                      <span>
                        <b>Add to Cart</b>
                      </span>
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Story;
