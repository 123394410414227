import React, { useContext } from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import "../css/privacy.scss";
import { CartContext } from "./CartContext";
import { Link, useNavigate } from "react-router-dom";
import productData from "../Datas/productData";

function ReturnPrivacy() {
  const { addToCart } = useContext(CartContext);
  const navigate = useNavigate();

  const handleAddToCart = (id) => {
    addToCart(id, 1);

    navigate("/cartpage");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Header />
      <div className="privacy_box">
        <section
          id="shopify-section-template--23914697458043__main"
          className="shopify-section"
        >
          <div className="page-width page-width--narrow page-content">
            <header className="section-header">
              <h1 className="section-header__title">
                Return &amp; Exchange Policy
              </h1>
            </header>
            <div className="rte rte--nomargin">
              <p>
                <strong>
                  We want you to be delighted every time you shop with us.
                  Occasionally though, we know you may want to return or
                  exchange items.
                </strong>
              </p>
              <h2>30 Day Money Back Guarantee</h2>
              <p>
                We’ll gladly accept returns for any&nbsp;Wellgard products
                purchased from Wellgard.co.uk and will issue a full refund upon
                receiving your items within 30 days of the PURCHASE date. All
                returns must be pre-approved by customer service.
              </p>
              <p>
                Please contact Customer Service within 48hr should you have any
                discrepancies with your order.
              </p>
              <h3>Customer Service Contact Options</h3>
              <p>
                Our Customer Service team is&nbsp;
                <strong>
                  available five&nbsp;days per week.<span>&nbsp;</span>
                </strong>
                We are open from 9 am to 5 pm on Monday to Friday. A full list
                of contact options can be found below.
              </p>
              <ul>
                <li>
                  <strong>Contact Customer Support</strong>
                  <span>&nbsp;</span>– Question about our products or your
                  order? Then call us for free on<span>&nbsp;</span>01273 604035
                </li>
                <li>
                  <strong>Contact Form</strong>
                  <span>&nbsp;</span>- Complete the form below and we’ll respond
                  as quickly as we can (usually within 4 hours):
                </li>
                <li>
                  <strong>Write to Us</strong>
                  <span>&nbsp;</span>-&nbsp; Unit 9 Westergate Business Centre,
                  Moulsecoomb, Brighton, BN2 4QN
                </li>
              </ul>
              <div className="main">
                <section className="section section--page page">
                  <div className="container container--tiny">
                    <div className="page__content rte">
                      <p>
                        Wellgard&nbsp;is a trading name of&nbsp;
                        <Link onClick={toTop} to="/" title="Bayagan Group Ltd">
                          Bayagan Group Ltd
                        </Link>
                        . Registered office: The Old Casino, 28 Fourth Avenue,
                        Hove, England, BN3 2PJ. Registered in England and Wales:
                        Company number<span>&nbsp;</span>
                        <Link onClick={toTop} to="/">
                          10271296
                        </Link>
                        . Registered VAT number 248023422.
                      </p>
                    </div>
                  </div>
                </section>
              </div>
              <h2>Return Policy</h2>
              <p>
                Our return policy remains valid for 30 days from the date of
                your purchase. Should this period have elapsed, we regret to
                inform you that we are no longer able to offer a refund or
                exchange. To be eligible for a return, items must be unused, in
                the same condition as when received, and in the original
                packaging. A receipt or proof of purchase is also necessary to
                facilitate the processing of your return.
              </p>
              <p>
                Please note that refunds cannot be issued for empty containers
                or products that have been fully used. Returns of opened items
                are only accepted if they are faulty. We may require
                photographic evidence to support claims of damage, faults, or
                missing components.
              </p>
              <p>
                Upon receipt of the returned items, we will process refunds as
                appropriate. In certain instances, partial refunds may be
                granted for items that are returned not in their original
                condition, are damaged, or are missing parts due to reasons not
                attributable to our error. Items returned more than 30 days
                after delivery do not qualify for a full refund. We appreciate
                your understanding of our policies, which are designed to
                maintain the quality and integrity of our products and to
                prevent the misuse of our return system.
              </p>
              <h2>Refunds (if applicable)</h2>
              <p>
                Once your return is received and inspected, we will send you an
                email to notify you that we have received your returned item. We
                will also notify you of the approval or rejection of your
                refund.
              </p>
              <p>
                If you are approved, then your refund will be processed, and a
                credit will automatically be applied to your credit card or
                original method of payment, within a certain amount of days.
              </p>
              <h2>Late or missing refunds (if applicable)</h2>
              <p>
                If you haven’t received a refund yet, first check your bank
                account again.Then contact your credit card company, it may take
                some time before your refund is officially posted.
                <br />
                <br />
                Next contact your bank. There is often some processing time
                before a refund is posted. If you’ve done all of this and you
                still have not received your refund yet, please contact us at
                support@wellgard.co.uk.
              </p>
              <h2>Sale Items (if applicable)</h2>
              <p>
                Only regular priced items may be refunded, unfortunately sale
                items cannot be refunded.
              </p>
              <h2>Exchanges (if applicable)</h2>
              <p>
                We only replace items if they are defective or damaged. If you
                need to exchange it for the same item, send us an email at
                support@wellgard.co.uk and send your item to: Bayagan Group Ltd,
                Unit 9 Westergate Business Centre, Moulsecoomb, Brighton, BN2
                4QN
              </p>
              <h2>Return Shipping</h2>
              <p>
                To return your product, you should mail your product to: Bayagan
                Group Ltd, Unit 9 Westergate Business Centre, Moulsecoomb,
                Brighton, BN2 4QN You will be responsible for paying for your
                own shipping costs for returning your item.
              </p>
              <h2>Shipping costs are non-refundable.</h2>
              <p>
                If you receive a refund, the cost of return shipping will be
                deducted from your refund. Depending on where you live, the time
                it may take for your exchanged product to reach you, may vary.
              </p>
              <p>
                If you are shipping an item over £25, you should consider using
                a trackable shipping service or purchasing shipping insurance.
                We don’t guarantee that we will receive your returned item.
              </p>
            </div>
          </div>
        </section>
        <div
          id="shopify-section-template--23914697458043__1636051971197314b2"
          className="shopify-section index-section"
        >
          <div className="text-left page-width page-width--narrow">
            <div className="theme-block">
              <h2>WE ARE HERE FOR YOU</h2>
            </div>
            <div className="theme-block">
              <div className="rte">
                <p>
                  If you want to find out more about us and our products, please
                  feel free to get in touch.
                </p>
                <p>
                  Our Customer Service team is&nbsp;
                  <strong>available five&nbsp;days per week.&nbsp;</strong>We
                  are open from 9 am to 5 pm on Monday to Friday. A full list of
                  contact options can be found below.
                </p>
                <p>
                  <strong>Contact Customer Support</strong>&nbsp;– Question
                  about our products or your order? Then call us for free
                  on&nbsp;01273 604035
                </p>
                <p>
                  <strong>Customer Support Email</strong>&nbsp;- You can email
                  your queries to support@wellgard.co.uk
                </p>
                <p>
                  <strong>Write to Us</strong>&nbsp;-&nbsp; Unit 9 Westergate
                  Business Centre, Moulsecoomb, Brighton, BN2 4QN
                </p>
                <p>
                  Wellgard is a trading name of&nbsp;
                  <a
                    href="https://beta.companieshouse.gov.uk/company/10271296"
                    title="https://beta.companieshouse.gov.uk/company/10271296"
                  >
                    Bayagan Group Ltd
                  </a>
                  . Registered office: The Old Casino, 28 Fourth Avenue, Hove,
                  England, BN3 2PJ. Registered in England and Wales: Company
                  number 10271296. Registered VAT number 248023422.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div
            className="row_three"
            style={{ maxWidth: "1300px", margin: "0 auto" }}
          >
            <h4 class="primary-title">
              <h2>Shop our best-sellers</h2>
            </h4>
            <div className="rebuy_product_grid">
              {productData
                .filter((e) => [1, 2, 3, 10].includes(e.id))
                .map((product, index) => (
                  <div
                    className="rebuy-product-block col-big-3 col-mid-3 col-sm-6 col-xs-6"
                    key={index}
                  >
                    <div className="rebuy-product-media">
                      <img
                        loading="eager"
                        src={product.images[0]}
                        alt={product.name}
                      />
                    </div>

                    <div className="rebuy-product-info">
                      <div className="rebuy-product-title clickable">
                        {product.name}
                      </div>
                      <div className="rebuy-product-price">
                        <div>
                          <span
                            className={`rebuy-money ${
                              product.prePrice ? "money_red" : ""
                            }`}
                          >
                            <span tabIndex={0}>£{product.price}</span>
                          </span>
                          {product.prePrice && (
                            <span class="rebuy-money compare-at">
                              <span>£{product.prePrice}</span>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="rebuy-product-actions">
                      <button
                        aria-label="Add the product, Gutstar 20 Billion CFU Probiotics For Gut Health to Cart"
                        type="button"
                        className="rebuy-button"
                        onClick={() => handleAddToCart(product.id)}
                      >
                        <span>
                          <b>Add to Cart</b>
                        </span>
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ReturnPrivacy;
