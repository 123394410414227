import React, { useContext } from "react";
import { CartContext } from "../Component/CartContext";
import productData from "../Datas/productData";
import Header from "./common/Header";
import "../css/cartpage.scss";
import { useNavigate, Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

const CartPage = () => {
  const { cartItems, removeFromCart, updateQuantity } = useContext(CartContext);
  const navigate = useNavigate();

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  const toCheckOut = () => {
    if (cartItems.length === 0) {
      alert("Please add items to your cart before proceeding to checkout.");
      return;
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/checkout");
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => {
      const product = getProductById(item.id);
      if (product) {
        const price = parseFloat(product.price);
        const quantity = item.quantity || 1;
        if (!isNaN(price) && price > 0 && quantity > 0) {
          total += price * quantity;
        }
      }
      return total;
    }, 0);
  };

  const handleQuantityChange = (itemId, change) => {
    const item = cartItems.find((cartItem) => cartItem.id === itemId);
    if (!item) return;
    const newQuantity = item.quantity + change;
    if (newQuantity > 0) {
      updateQuantity(itemId, newQuantity);
    }
  };

  return (
    <>
      <div className="main-container">
        <Header />
        <div className="cart-page">
          <div className="basket-title">
            <h1>Shopping Basket</h1>
          </div>
          <div className="cart-total">
            <div className="cart-items">
              {cartItems.length === 0 ? (
                <h3 style={{ color: "black", textAlign: "center" }}>
                  Your Cart is Empty!
                </h3>
              ) : (
                <Table aria-label="shopping cart table">
                  <TableBody className="col-big-12 col-mid-12 col-sm-12 col-xs-12">
                    {cartItems.map((item, index) => {
                      const product = getProductById(item.id);
                      return (
                        <TableRow key={index}>
                          <TableCell
                            component="th"
                            scope="row"
                            className="col-big-2 col-mid-2 col-sm-3 col-xs-3"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={product.images[0]}
                              alt="Product"
                              style={{
                                objectFit: "contain",
                              }}
                              className="egg_img"
                            />
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              borderRight: "1px dotted #ccc",
                            }}
                            className="col-big-7 col-mid-7 col-sm-6 col-xs-6"
                          >
                            <div>
                              <h4 className="egg_title">{product.name}</h4>
                              <div className="rebuy-cart__flyout-item-quantity-widget">
                                <button
                                  alt="Decrease quantity of Collagen Powder"
                                  aria-label="Decrease quantity of Collagen Powder"
                                  type="button"
                                  className="rebuy-cart__flyout-item-quantity-widget-button"
                                  onClick={() =>
                                    handleQuantityChange(item.id, -1)
                                  }
                                >
                                  <svg
                                    viewBox="0 0 32 32"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <defs>
                                      <style
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            ".cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}",
                                        }}
                                      />
                                    </defs>
                                    <title />
                                    <g id="minus">
                                      <line
                                        className="cls-1"
                                        x1={7}
                                        x2={25}
                                        y1={16}
                                        y2={16}
                                      />
                                    </g>
                                  </svg>
                                </button>{" "}
                                <span className="rebuy-cart__flyout-item-quantity-widget-label">
                                  {item.quantity || 1}
                                </span>{" "}
                                <button
                                  alt="Increase quantity of Collagen Powder"
                                  aria-label="Increase quantity of Collagen Powder"
                                  type="button"
                                  className="rebuy-cart__flyout-item-quantity-widget-button"
                                  onClick={() =>
                                    handleQuantityChange(item.id, 1)
                                  }
                                >
                                  <svg
                                    viewBox="0 0 32 32"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <defs>
                                      <style
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            ".cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}",
                                        }}
                                      />
                                    </defs>
                                    <title />
                                    <g id="plus">
                                      <line
                                        className="cls-1"
                                        x1={16}
                                        x2={16}
                                        y1={7}
                                        y2={25}
                                      />
                                      <line
                                        className="cls-1"
                                        x1={7}
                                        x2={25}
                                        y1={16}
                                        y2={16}
                                      />
                                    </g>
                                  </svg>
                                </button>
                              </div>
                              <div className="rebuy-cart__flyout-item-price">
                                <span className="rebuy-money sale">
                                  <span>£{product.price}</span>
                                </span>
                              </div>
                            </div>
                          </TableCell>

                          <TableCell
                            align="center"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              borderRight: "2px solid rgb(243, 243, 243)",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                            className="col-big-3 col-mid-3 col-sm-3 col-xs-3"
                          >
                            <Link
                              to="/cartpage"
                              onClick={() => removeFromCart(item.id)}
                              style={{
                                cursor: "context-menu",
                                textDecoration: "underline",
                                fontFamily: "Libre Baskerville",
                                fontSize: "13px",
                                fontWeight: "600",
                              }}
                              className="egg_delete_item"
                            >
                              <svg
                                enableBackground="new 0 0 512 512"
                                height="512px"
                                id="Layer_1"
                                version="1.1"
                                viewBox="0 0 512 512"
                                width="512px"
                                xmlSpace="preserve"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                              >
                                <g>
                                  <path
                                    d="M444.852,66.908h-99.339V47.04c0-21.943-17.792-39.736-39.736-39.736h-99.339   c-21.944,0-39.736,17.793-39.736,39.736v19.868H67.363v19.868h20.47l19.887,377.489c0,21.944,17.792,39.736,39.736,39.736h218.546   c21.944,0,39.736-17.792,39.736-39.736l19.538-377.489h19.577V66.908z M186.57,47.04c0-10.962,8.926-19.868,19.868-19.868h99.339   c10.962,0,19.868,8.906,19.868,19.868v19.868H186.57V47.04z M385.908,463.236l-0.039,0.505v0.524   c0,10.943-8.906,19.868-19.868,19.868H147.455c-10.942,0-19.868-8.925-19.868-19.868v-0.524l-0.019-0.523L107.72,86.776h297.669   L385.908,463.236z"
                                    fill="#37404D"
                                  />
                                  <rect
                                    fill="#37404D"
                                    height="317.885"
                                    width="19.868"
                                    x="246.173"
                                    y="126.511"
                                  />
                                  <polygon
                                    fill="#37404D"
                                    points="206.884,443.757 186.551,126.493 166.722,127.753 187.056,445.017  "
                                  />
                                  <polygon
                                    fill="#37404D"
                                    points="345.649,127.132 325.82,125.891 305.777,443.776 325.606,445.017  "
                                  />
                                </g>
                              </svg>
                            </Link>
                            {/* <div className="rebuy-cart__flyout-item-price">
                              <span className="rebuy-money sale">
                                <span>£{product.price}</span>
                              </span>
                            </div> */}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
              {cartItems.length > 0 && (
                <div className="cart-summary">
                  <div className="rightSum">
                    <table>
                      <tbody>
                        <tr>
                          <td className="td-left">
                            Subtotal ({cartItems.length} items)
                          </td>
                          <td> £{calculateTotal().toFixed(2)}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="btn___box">
                      <button className="checkout-button" onClick={toCheckOut}>
                        Checkout
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartPage;
