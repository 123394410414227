const reviewsData = [
  {
    id: 1,
    bac: "M",
    name: " Marc ",
    title: "Good Collagen",
    des: "Good collagen. It dissolve instantly in hot and even cold drink.It doesn't have any taste, very neutral.",
    images: [
      "https://m.media-amazon.com/images/I/614fw-PvUTL._SY88.jpg",
      "https://m.media-amazon.com/images/I/61VpKGmVDHL._SY88.jpg",
      "https://m.media-amazon.com/images/I/61u06Qm20gL._SY88.jpg",
      "https://m.media-amazon.com/images/I/71-YE9IZA6L._SY88.jpg",
    ],
    time: "23 November 2024",
    like: 0,
  },
  {
    id: 2,
    bac: "E",
    name: " Eugnia ",
    title: "Healthy hair nails and skin",
    des: "The media could not be loaded. I was advised from a health professional to start taking collagen as I was losing a lot of hair,I had a look around and this one was the best strength I could find and the price is very good, I’m on this supplement for 3 months and I can say that my skin is definitely healthier, not losing as much hair as before and my nails are looking also a lot better! This drink is nearly tasteless I can easily drink only mixing with water very easy doesn’t have smell at all it’s also very easy to mix doesn’t lump at all as you can see in one of my pictures! The size of the bottle is very good as it last me until next delivery (I liked it so much that I put on subscription). I can’t recommend this enough.",
    images: [
      "https://m.media-amazon.com/images/I/61U+aq9ZYGL._SY88.jpg",
      "https://m.media-amazon.com/images/I/61tsRW7d3XL._SY88.jpg",
    ],
    time: "14 November 2024",
    like: 0,
  },
  {
    id: 3,
    bac: "R",
    name: " Raju ",
    title: "My nails have grown much quicker and feel stronger",
    des: "Easy to take in my coffee or oats but I do feel like it has a slight after taste and sometimes clumps of you don’t stir it straightaway or enough but overall I do feel like my nails and hair feel better",
    images: ["https://m.media-amazon.com/images/I/61I3qgbk7qL._SY88.jpg"],
    time: "6 November 2024",
    like: 0,
  },
  {
    id: 4,
    bac: "T",
    name: " Tammi Kirlew ",
    title: "Collagen powder",
    des: "The media could not be loaded. So far it's early days as only had it 4 days now. Just wanted to show the contents of the tub as its brimming to the top with powder, as some people have a half full tub.I mix my dose in my morning espresso and can say there's no difference in the taste of my coffee and it mixes well leaving no clumps.It's,tasteless, mixes well, and very cost effective so I would recommend.When I've completed this tub I will update review with regards my nails, skin, hair etc",
    images: [
      "https://m.media-amazon.com/images/I/61iRaR4b+ML._SY88.jpg",
      "https://m.media-amazon.com/images/I/7125Z9hyUiL._SY88.jpg",
      "https://m.media-amazon.com/images/I/71cdWFRjk8L._SY88.jpg",
    ],
    time: "28 October 2024 ",
    like: 0,
  },
  {
    id: 5,
    bac: "K",
    name: "  kmefb ",
    title: "Pot very low on contents",
    des: "I love this product, and have it on a regular subscription.....however my delivery today was not good. The item came missing a hood few inches of powder - usually these tubs are full! I feel like I've been charged for 2/3 of the actual product. If the next pot is like this I'll be changing my subscription!",
    images: ["https://m.media-amazon.com/images/I/51HuDzbtjiL._SY88.jpg"],
    time: "21 October 2024",
    like: 0,
  },
  {
    id: 6,
    bac: "Y",
    name: " Ysmerlin ",
    title: "Excellent!",
    des: "It's the 4th time I buy it, it's really good and the size / price is perfect!",
    images: ["https://m.media-amazon.com/images/I/615IhdZzEYL._SY88.jpg"],
    time: "9 October 2024",
    like: 0,
  },
  {
    id: 7,
    bac: "P",
    name: " Pat  ",
    title: "Great for saggy skin",
    des: "My dad lost a lot of weight and his skin was loose and thin. This definitely helped his skin to tighten and be less saggy. Now he uses it religiously.I add it to my coffee, the serving size is two table spoons. The taste is almost unnoticeable in coffee and dissolves easily. It’s good quality.I use a scoop from some other supplements as this makes it easier to not spill.",
    images: ["https://m.media-amazon.com/images/I/51nsijaj3oL._SY88.jpg"],
    time: "24 September 2024",
    like: 0,
  },
  {
    id: 8,
    bac: "N",
    name: " Natasha  ",
    title: "Very full packed",
    des: "It’s too early to say as it’s only my day 2 but how full botle it was I’m still shocked very full package everything it okay and delivered safely I will come back again least after 2 weeks",
    images: ["https://m.media-amazon.com/images/I/71fjv1NbV5L._SY88.jpg"],
    time: "1 September 2024",
    like: 0,
  },
  {
    id: 9,
    bac: "W",
    name: " Wellgard Customer ",
    title: "Collagen power",
    des: "Light weight use it in my tea of a morning great price and the tube was full to the top haven't noticed a difference yet but only been on it a few days",
    images: [
      "https://m.media-amazon.com/images/I/619na-rSndL._SY88.jpg",
      "https://m.media-amazon.com/images/I/7192pUyDqyL._SY88.jpg",
    ],
    time: "1 September 2024",
    like: 0,
  },
  {
    id: 10,
    bac: "T",
    name: " Tina McNally ",
    title: "Wellgard pure collagen",
    des: "This is my first purchase was recommended by a friend came packaged well used it for first time seems ok no taste to it time will tell",
    images: ["https://m.media-amazon.com/images/I/61K7yOcF3CL._SY88.jpg"],
    time: "30 July 2023",
    like: 0,
  },
  {
    id: 11,
    bac: "S",
    name: " Samandodo  ",
    title: "For those who were asking halal or not",
    des: "It isn't clear on Amazon website but if you go onto company website it clearly states it's halal and kosher suitable. Also pic attached . Haven't tried it yet.",
    images: ["https://m.media-amazon.com/images/I/71OLD5HoblL._SY88.jpg"],
    time: "5 July 2023 ",
    like: 0,
  },
  {
    id: 12,
    bac: "H",
    name: " Huma Hamid ",
    title: "5.0 out of 5 stars Wellguard collagen",
    des: "Amazing products been using for over three years a must buy and it’s halal",
    images: ["https://m.media-amazon.com/images/I/51iyXHnJcKL._SY88.jpg"],
    time: "15 June 2023",
    like: 1,
  },
  {
    id: 13,
    bac: "M",
    name: " M ",
    title: "Good value",
    des: "",
    images: ["https://m.media-amazon.com/images/I/61txx8kuecL._SY88.jpg"],
    time: "25 May 2023",
    like: 0,
  },
  {
    id: 14,
    bac: "P",
    name: " Peter J. ",
    title: "It is good",
    des: "My hair it is getting better!",
    images: ["https://m.media-amazon.com/images/I/61WuWaOUXoL._SY88.jpg"],
    time: "16 May 2023",
    like: 0,
  },
  {
    id: 15,
    bac: "D",
    name: " Dina Rourke ",
    title: "Good quality powder",
    des: "This collagen powder does dissolve very easily. I compare with previous collagen I was buying from other , more expensive brand, and this product is sooo much better. It doesn’t smell or taste at all and it is nice with any drink or soup. I usually put 2tbs at once. Highly recommend",
    images: [
      "https://m.media-amazon.com/images/I/61a4EPv3pBL._SY88.jpg",
      "https://m.media-amazon.com/images/I/61j5CqOFc-L._SY88.jpg",
    ],
    time: "15 May 2023",
    like: 1,
  },
];

export default reviewsData;
