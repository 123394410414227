import React, { useContext } from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import "../css/privacy.scss";
import { CartContext } from "./CartContext";
import { Link, useNavigate } from "react-router-dom";
import productData from "../Datas/productData";

function Privacy() {
  const { addToCart } = useContext(CartContext);
  const navigate = useNavigate();

  const handleAddToCart = (id) => {
    addToCart(id, 1);

    navigate("/cartpage");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Header />
      <div className="privacy_box">
        <section
          id="shopify-section-template--23914697458043__main"
          className="shopify-section"
        >
          <div className="page-width page-width--narrow page-content">
            <header className="section-header">
              <h1 className="section-header__title">Privacy Policy</h1>
            </header>
            <div className="rte rte--nomargin">
              <meta charSet="utf-8" />
              <div className="main">
                <section className="section section--page page">
                  <div className="container container--tiny">
                    <div className="page__content rte">
                      <meta charSet="utf-8" />
                      <meta charSet="utf-8" />
                      <p>
                        <span>Wellgard&nbsp;is a trading name of&nbsp;</span>
                        <Link onClick={toTop} to="/">
                          Bayagan Group Ltd
                        </Link>
                        <span>
                          . Registered office: The Old Casino, 28 Fourth Avenue,
                          Hove, England, BN3 2PJ. Registered in England and
                          Wales: Company number 10271296. Registered VAT number
                          248023422.
                        </span>
                      </p>
                      <h3>
                        <strong>
                          SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?
                        </strong>
                      </h3>
                      <p>
                        When you purchase something from our store, as part of
                        the buying and selling process, we collect the personal
                        information you give us such as your name, address and
                        email address.
                      </p>
                      <p>
                        When you browse our store, we also automatically receive
                        your computer’s internet protocol (IP) address in order
                        to provide us with information that helps us learn about
                        your browser and operating system.
                        <br />
                        <br />
                        Email marketing (if applicable): With your permission,
                        we may send you emails about our store, new products and
                        other updates.
                      </p>
                      <p className="p1">
                        <span className="s1">
                          <b>We do not store credit card</b>
                          <span>&nbsp;</span>
                          <b>
                            details nor do we share financial details with any
                            3rd parties.
                          </b>
                        </span>
                      </p>
                      <h3>
                        <strong>SECTION 2 - CONSENT</strong>
                      </h3>
                      <p>
                        How do you get my consent?<span>&nbsp;</span>
                        <br />
                        When you provide us with personal information to
                        complete a transaction, verify your credit card, place
                        an order, arrange for a delivery or return a purchase,
                        we imply that you consent to our collecting it and using
                        it for that specific reason only.
                        <span>&nbsp;</span>
                        <br />
                        If we ask for your personal information for a secondary
                        reason, like marketing, we will either ask you directly
                        for your expressed consent, or provide you with an
                        opportunity to say no.
                      </p>
                      <p>
                        How do I withdraw my consent?<span>&nbsp;</span>
                        <br />
                        If after you opt-in, you change your mind, you may
                        withdraw your consent for us to contact you, for the
                        continued collection, use or disclosure of your
                        information, at anytime, by contacting us at
                        support@wellgard.co.uk or mailing us at: Unit 9
                        Westergate Business Centre, Moulsecoomb, Brighton, BN2
                        4QN
                      </p>
                      <h3>
                        <strong>SECTION 3 - DISCLOSURE</strong>
                      </h3>
                      <p>
                        We may disclose your personal information if we are
                        required by law to do so or if you violate our Terms of
                        Service.
                      </p>
                      <h3>
                        <strong>SECTION 4 -&nbsp;SHOPIFY</strong>
                      </h3>
                      <p>
                        Our store is hosted on Shopify Inc. They provide us with
                        the online e-commerce platform that allows us to sell
                        our products and services to you.
                        <br />
                        <br />
                        Your data is stored through Shopify’s data storage,
                        databases and the general Shopify application. They
                        store your data on a secure server behind a firewall.
                      </p>
                      <p>
                        <strong>
                          Payment:<span>&nbsp;</span>
                        </strong>
                        <br />
                        If you choose a direct payment gateway to complete your
                        purchase, then Shopify stores your credit card data. It
                        is encrypted through the Payment Card Industry Data
                        Security Standard (PCI-DSS). Your purchase transaction
                        data is stored only as long as is necessary to complete
                        your purchase transaction. After that is complete, your
                        purchase transaction information is deleted.
                        <span>&nbsp;</span>
                        <br />
                        <br />
                        All direct payment gateways adhere to the standards set
                        by PCI-DSS as managed by the PCI Security Standards
                        Council, which is a joint effort of brands like Visa,
                        MasterCard, American Express and Discover.
                        <span>&nbsp;</span>
                        <br />
                        <br />
                        PCI-DSS requirements help ensure the secure handling of
                        credit card information by our store and its service
                        providers.
                        <span>&nbsp;</span>
                        <br />
                        <br />
                        For more insight, you may also want to read Shopify’s
                        Terms of Service (https://www.shopify.com/legal/terms)
                        or Privacy Statement
                        (https://www.shopify.com/legal/privacy).
                      </p>
                      <h3>
                        <strong>SECTION 5 - THIRD-PARTY SERVICES</strong>
                      </h3>
                      <p>
                        In general, the third-party providers used by us will
                        only collect, use and disclose your information to the
                        extent necessary to allow them to perform the services
                        they provide to us.<span>&nbsp;</span>
                        <br />
                        <br />
                        However, certain third-party service providers, such as
                        payment gateways and other payment transaction
                        processors, have their own privacy policies in respect
                        to the information we are required to provide to them
                        for your purchase-related transactions.
                        <br />
                        <br />
                        For these providers, we recommend that you read their
                        privacy policies so you can understand the manner in
                        which your personal information will be handled by these
                        providers.
                        <span>&nbsp;</span>
                        <br />
                        <br />
                        In particular, remember that certain providers may be
                        located in or have facilities that are located a
                        different jurisdiction than either you or us. So if you
                        elect to proceed with a transaction that involves the
                        services of a third-party service provider, then your
                        information may become subject to the laws of the
                        jurisdiction(s) in which that service provider or its
                        facilities are located.<span>&nbsp;</span>
                        <br />
                        <br />
                        As an example, if you are located in Canada and your
                        transaction is processed by a payment gateway located in
                        the United States, then your personal information used
                        in completing that transaction may be subject to
                        disclosure under United States legislation, including
                        the Patriot Act.<span>&nbsp;</span>
                        <br />
                        <br />
                        Once you leave our store’s website or are redirected to
                        a third-party website or application, you are no longer
                        governed by this Privacy Policy or our website’s Terms
                        of Service.
                      </p>
                      <p>
                        Links<span>&nbsp;</span>
                        <br />
                        When you click on links on our store, they may direct
                        you away from our site. We are not responsible for the
                        privacy practices of other sites and encourage you to
                        read their privacy statements.
                        <br />
                        <br />
                        Google analytics:<span>&nbsp;</span>
                        <br />
                        Our store uses Google Analytics to help us learn about
                        who visits our site and what pages are being looked at
                      </p>
                      <h3>SECTION 6 - SECURITY</h3>
                      <p>
                        To protect your personal information, we take reasonable
                        precautions and follow industry best practices to make
                        sure it is not inappropriately lost, misused, accessed,
                        disclosed, altered or destroyed.
                        <br />
                        <br />
                        If you provide us with your credit card information, the
                        information is encrypted using secure socket layer
                        technology (SSL) and stored with a AES-256 encryption.
                        Although no method of transmission over the Internet or
                        electronic storage is 100% secure, we follow all PCI-DSS
                        requirements and implement additional generally accepted
                        industry standards.
                      </p>
                      <h3>SECTION 7 - COOKIES</h3>
                      <p>
                        Here is a list of cookies that we use. We’ve listed them
                        here so you that you can choose if you want to opt-out
                        of cookies or not.<span>&nbsp;</span>
                        <br />
                        <br />
                        _session_id, unique token, sessional, Allows Shopify to
                        store information about your session (referrer, landing
                        page, etc).
                        <span>&nbsp;</span>
                        <br />
                        _shopify_visit, no data held, Persistent for 30 minutes
                        from the last visit, Used by our website provider’s
                        internal stats tracker to record the number of visits
                        <span>&nbsp;</span>
                        <br />
                        _shopify_uniq, no data held, expires midnight (relative
                        to the visitor) of the next day, Counts the number of
                        visits to a store by a single customer.
                        <span>&nbsp;</span>
                        <br />
                        cart, unique token, persistent for 2 weeks, Stores
                        information about the contents of your cart.
                        <span>&nbsp;</span>
                        <br />
                        _secure_session_id, unique token, sessional
                        <span>&nbsp;</span>
                        <br />
                        storefront_digest, unique token, indefinite If the shop
                        has a password, this is used to determine if the current
                        visitor has access.<span>&nbsp;</span>
                        <br />
                        PREF, persistent for a very short period, Set by Google
                        and tracks who visits the store and from where
                      </p>
                      <h3>SECTION 8 - AGE OF CONSENT</h3>
                      <p>
                        By using this site, you represent that you are at least
                        the age of majority in your state or province of
                        residence, or that you are the age of majority in your
                        state or province of residence and you have given us
                        your consent to allow any of your minor dependents to
                        use this site.
                      </p>
                      <h3>SECTION 9 - CHANGES TO THIS PRIVACY POLICY</h3>
                      <p>
                        We reserve the right to modify this privacy policy at
                        any time, so please review it frequently. Changes and
                        clarifications will take effect immediately upon their
                        posting on the website. If we make material changes to
                        this policy, we will notify you here that it has been
                        updated, so that you are aware of what information we
                        collect, how we use it, and under what circumstances, if
                        any, we use and/or disclose it.
                        <br />
                        <br />
                        If our store is acquired or merged with another company,
                        your information may be transferred to the new owners so
                        that we may continue to sell products to you.
                      </p>
                      <h3>QUESTIONS AND CONTACT INFORMATION</h3>
                      <p>
                        If you would like to: access, correct, amend or delete
                        any personal information we have about you, register a
                        complaint, or simply want more information contact our
                        Privacy Compliance Officer&nbsp;using our contact
                        information below.&nbsp;
                      </p>
                      <p>
                        We are&nbsp;
                        <strong>
                          available five&nbsp;days per week.<span>&nbsp;</span>
                        </strong>
                        We are open from 9 am to 5 pm on Monday to Friday. A
                        full list of contact options can be found below.
                      </p>
                      <ul>
                        <li>
                          <strong>Contact Customer Support</strong>
                          <span>&nbsp;</span>– Question about our products or
                          your order? Then call us for free on
                          <span>&nbsp;</span>01273 604035
                        </li>
                        <li>
                          <strong>Customer Support Email</strong>
                          <span>&nbsp;</span>- You can email your queries to
                          support@wellgard.co.uk
                        </li>
                        <li>
                          <strong>Write to Us</strong>
                          <span>&nbsp;</span>-&nbsp; Unit 9 Westergate Business
                          Centre, Moulsecoomb, Brighton, BN2 4QN
                        </li>
                      </ul>
                      <p>
                        <span>
                          Wellgard&nbsp;is a trading name of Bayagan
                          <Link onClick={toTop} to="/">
                            &nbsp;Group Ltd
                          </Link>
                        </span>
                        <span>
                          . Registered office: The Old Casino, 28 Fourth Avenue,
                          Hove, England, BN3 2PJ. Registered in England and
                          Wales: Company number 10271296. Registered VAT number
                          248023422.
                        </span>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
              <div className="shopify-section js-section__footer" />
            </div>
          </div>
        </section>
        <div
          id="shopify-section-template--23914697458043__1636051971197314b2"
          className="shopify-section index-section"
        >
          <div className="text-left page-width page-width--narrow">
            <div className="theme-block">
              <h2>WE ARE HERE FOR YOU</h2>
            </div>
            <div className="theme-block">
              <div className="rte">
                <p>
                  If you want to find out more about us and our products, please
                  feel free to get in touch.
                </p>
                <p>
                  Our Customer Service team is&nbsp;
                  <strong>available five&nbsp;days per week.&nbsp;</strong>We
                  are open from 9 am to 5 pm on Monday to Friday. A full list of
                  contact options can be found below.
                </p>
                <p>
                  <strong>Contact Customer Support</strong>&nbsp;– Question
                  about our products or your order? Then call us for free
                  on&nbsp;01273 604035
                </p>
                <p>
                  <strong>Customer Support Email</strong>&nbsp;- You can email
                  your queries to support@wellgard.co.uk
                </p>
                <p>
                  <strong>Write to Us</strong>&nbsp;-&nbsp; Unit 9 Westergate
                  Business Centre, Moulsecoomb, Brighton, BN2 4QN
                </p>
                <p>
                  Wellgard is a trading name of&nbsp;
                  <Link onClick={toTop} to="/">
                    Bayagan Group Ltd
                  </Link>
                  . Registered office: The Old Casino, 28 Fourth Avenue, Hove,
                  England, BN3 2PJ. Registered in England and Wales: Company
                  number 10271296. Registered VAT number 248023422.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div
            className="row_three"
            style={{ maxWidth: "1300px", margin: "0 auto" }}
          >
            <h4 class="primary-title">
              <h2>Shop our best-sellers</h2>
            </h4>
            <div className="rebuy_product_grid">
              {productData
                .filter((e) => [1, 2, 3, 10].includes(e.id))
                .map((product, index) => (
                  <div
                    className="rebuy-product-block col-big-3 col-mid-3 col-sm-6 col-xs-6"
                    key={index}
                  >
                    <div className="rebuy-product-media">
                      <img
                        loading="eager"
                        src={product.images[0]}
                        alt={product.name}
                      />
                    </div>

                    <div className="rebuy-product-info">
                      <div className="rebuy-product-title clickable">
                        {product.name}
                      </div>
                      <div className="rebuy-product-price">
                        <div>
                          <span
                            className={`rebuy-money ${
                              product.prePrice ? "money_red" : ""
                            }`}
                          >
                            <span tabIndex={0}>£{product.price}</span>
                          </span>
                          {product.prePrice && (
                            <span class="rebuy-money compare-at">
                              <span>£{product.prePrice}</span>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="rebuy-product-actions">
                      <button
                        aria-label="Add the product, Gutstar 20 Billion CFU Probiotics For Gut Health to Cart"
                        type="button"
                        className="rebuy-button"
                        onClick={() => handleAddToCart(product.id)}
                      >
                        <span>
                          <b>Add to Cart</b>
                        </span>
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Privacy;
