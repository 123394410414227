import React, { useState, useEffect, useContext } from "react";
import "../css/product.scss";
import productData from "../Datas/productData";
import { Link } from "react-router-dom";
import { CartContext } from "./CartContext";
import { useNavigate } from "react-router-dom";

function AlsoBuy() {
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const [openSections, setOpenSections] = useState({});
  const { addToCart } = useContext(CartContext);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    setSelectedItems([1, 4, 5]);
  }, []);

  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleToggle = (id) => {
    setOpenSections((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleCheckboxChange = (productId) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(productId)) {
        return prevSelectedItems.filter((id) => id !== productId);
      } else {
        return [...prevSelectedItems, productId];
      }
    });
  };
  const handleAddToCart2 = () => {
    selectedItems.forEach((id) => {
      addToCart(id, 1);
    });

    navigate("/cartpage");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const calculateTotalPrice = () => {
    return selectedItems.reduce((total, itemId) => {
      const product = productData.find((item) => item.id === itemId);

      if (product) {
        const price = parseFloat(product.price);
        const quantity = 1;
        if (!isNaN(price) && price > 0 && quantity > 0) {
          total += price * quantity;
        }
      }

      return total;
    }, 0);
  };

  return (
    <>
      <div className="row_two">
        <div className="row_two_inner">
          <h3 class="primary-title">
            <h2>🛍️ Frequently bought together</h2>
          </h3>

          <div className="rebuy-bundle">
            <div className="rebuy-bundle__images col-big-6 col-mid-12 col-sm-12 col-xs-12">
              {productData
                .filter((product) => [1, 4, 5].includes(product.id))
                .map((buy, index, array) => (
                  <div className="rebuy-bundle__image" key={buy.id}>
                    <div className="rebuy_img_box">
                      <img src={buy.images[0]} alt={buy.name} />
                    </div>
                    {index < array.length - 1 && (
                      <div className="rebuy-bundle__image-divider">
                        <i className="far fa-plus" />
                      </div>
                    )}
                  </div>
                ))}
            </div>
            <div className="rebuy-bundle__actions col-big-6 col-mid-12 col-sm-12 col-xs-12">
              <div className="rebuy-bundle__actions-price">
                <strong className="rebuy-bundle__actions-price-label">
                  Total Price:
                </strong>
                <div className="rebuy-bundle__actions-price-value">
                  <span className="rebuy-money sale">
                    £{calculateTotalPrice()}
                  </span>
                </div>
              </div>
              <div className="rebuy-bundle__actions-buttons">
                <button
                  type="button"
                  className="rebuy-button"
                  onClick={handleAddToCart2}
                >
                  <span>Add to Cart</span>
                </button>
              </div>
            </div>
            <div className="rebuy-bundle__items col-big-12 col-mid-12 col-sm-12 col-xs-12">
              <label className="rebuy-bundle__label">
                Buy these items:
                <button
                  className="rebuy-bundle__accordion-toggle"
                  onClick={() => handleToggle("section-4")}
                >
                  <svg
                    width={8}
                    height={8}
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={`${openSections["section-4"] ? "rotated" : ""}`}
                  >
                    <path
                      d="M10.59 0.589844L6 5.16984L1.41 0.589844L0 1.99984L6 7.99984L12 1.99984L10.59 0.589844Z"
                      fill="#666E95"
                    />
                  </svg>
                </button>
              </label>
              <div
                className={`bundle__items_des ${
                  openSections["section-4"] ? "visible is-open" : "hidden"
                }`}
              >
                {productData
                  .filter((product) => [1, 4, 5].includes(product.id))
                  .map((buy) => (
                    <div
                      key={buy.id}
                      className="rebuy-product-block collagen-powder product-id-3670667067466 tag-26milliondose tag-_label_best-seller tag-spodefault tag-spodisabled is-input-product is-selected"
                    >
                      <div className="rebuy-product-info">
                        <div className="rebuy-product-checkbox">
                          <label className="rebuy-checkbox-label">
                            <input
                              type="checkbox"
                              className="checkbox-input rebuy-checkbox is-input-product"
                              checked={selectedItems.includes(buy.id)}
                              onChange={() => handleCheckboxChange(buy.id)}
                            />
                          </label>
                        </div>
                        {buy.id === 1 && (
                          <strong className="rebuy-product-label">
                            This item:
                          </strong>
                        )}

                        <Link
                          to="/"
                          onClick={toTop}
                          alt="View Collagen Powder"
                          className="rebuy-product-title"
                        >
                          {buy.name}
                        </Link>
                        <div className="rebuy-product-price">
                          <div>
                            <span className="rebuy-money sale">
                              £{buy.price}
                            </span>
                            {buy.prePrice && (
                              <span className="rebuy-money compare-at">
                                £{buy.prePrice}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AlsoBuy;
