const questionData = [
  {
    id: 1,
    bac: "S",
    name: " sabbah ",
    qes: "Is this collagen hydrolysate powder halal? ?",
    askTime: "2020-07-07T01:32:03.000000Z",
    answer: "Yes, it is 100% halal and kosher.",
    answerTime: "2020-07-09 17:37:56",
  },
  {
    id: 2,
    bac: "I",
    name: " ingrid jacoby ",
    qes: "Please can you tell me if this product has been tested for heavy metals, arsenic, mercury, antibiotics and other contaminants? Thank you so much. ?",
    askTime: "2020-07-05T09:50:05.000000Z",
    answer:
      "Yes, it has been tested heavy metals, allergens and antibiotics and its free from them all.",
    answerTime: "2020-07-09 17:38:46",
  },
  {
    id: 3,
    bac: "B",
    name: " Bob ",
    qes: "The collogen powder packaging states that it is hala & kosher. Can you confirm whether this statement refers to the production of the powder from raw materials, or are you saying that the raw materials are sourced from non stunned slaughtered animals. Thanks. ?",
    askTime: "2020-06-28T01:12:18.000000Z",
    answer:
      "The material is sourced from halal and kosher certified cow hides.",
    answerTime: "2020-07-09 17:40:27",
  },
  {
    id: 4,
    bac: "Y",
    name: " yahya ",
    qes: " I just wanted to inquire about it being halal or not.It says on your Amazon listing that it is halal/kosher, but on your website, I don't find anywhere detailed assurance. Can you confirm that is halal in the strictest sense?Thanks ?",
    askTime: "2020-06-23T03:21:36.000000Z",
    answer:
      "The product is 100% halal and kosher. These are on the product label.",
    answerTime: "2020-07-09 17:41:06",
  },
  {
    id: 5,
    bac: "D",
    name: " Debra Harrington ",
    qes: "Is it only two teaspoons daily ? ?",
    askTime: "2020-06-21T06:46:37.000000Z",
    answer:
      "Yes, its two tablespoons per day which is the equivalent of 13 grams per day.",
    answerTime: "2020-07-09 17:42:08",
  },
  {
    id: 6,
    bac: "I",
    name: " IA ",
    qes: " Is your product biovine grass fed and pasture raised? ?",
    askTime: "2020-06-19T07:18:03.000000Z",
    answer:
      'The cattle are raised mainly on a grass-based diet but we cannot guarantee, particularly in the winter months when grass crops fade, that the cattle feed is not supplemented with other food sources. Therefore, we cannot 100% guarantee the product is grass fed and hence do not make this claim, even though it may be the case that the cattle are grass-fed. There is no such thing as "100% grass fed collagen" and any brand that makes this claim will not be able to back it with unequivocal evidence. I can confirm the product is free from hormones and is NON-GMO.',
    answerTime: "2020-07-09 17:44:32",
  },
  {
    id: 7,
    bac: "A",
    name: " Andrew Bainbridge ",
    qes: "Hi there, Please could you tell me How many servings you get from a 400grm tub of collagen ? ?",
    askTime: "2020-05-29T04:01:16.000000Z",
    answer: "There are 31 servings per tub.",
    answerTime: "2020-07-09 17:44:53",
  },
  {
    id: 8,
    bac: "T",
    name: " Terry page ",
    qes: " How long does one tub last please? ?",
    askTime: "2020-05-28T06:25:07.000000Z",
    answer: "31 servings so just over a months worth of collagen supply.",
    answerTime: "2020-07-09 17:45:42",
  },
  {
    id: 9,
    bac: "A",
    name: " Alia ",
    qes: "Can you please verify if your product is halal verified? ?",
    askTime: "2020-05-23T01:47:20.000000Z",
    answer: "It is 100% halal and kosher certified.",
    answerTime: "2020-07-09 17:46:02",
  },
  {
    id: 10,
    bac: "P",
    name: " Patricia ",
    qes: "How many scoops a day how long would This tin last for...thank you.. ?",
    askTime: "2020-05-22T13:50:17.000000Z",
    answer:
      "The tub lasts around 31 days, you need two tablespoons of this collagen powder per day which is around 13grams.",
    answerTime: "2020-07-09 17:37:34",
  },
  {
    id: 11,
    bac: "C",
    name: " Curtis ",
    qes: "How many servings per tub thanks ?",
    askTime: "2020-05-14T21:03:18.000000Z",
    answer: "There are 31 servings per tub.",
    answerTime: "2020-07-09 17:46:25",
  },
  {
    id: 12,
    bac: "B",
    name: " Beverley Ward ",
    qes: "Can you let me know when this comes back in dtock i would like to subscribe ?",
    askTime: "2020-05-13T08:13:56.000000Z",
    answer: "This is always available for subscription.",
    answerTime: "2020-07-09 17:49:34",
  },
  {
    id: 13,
    bac: "S",
    name: " Sonia ",
    qes: " Its good fir skin ( wrinkles?)Tkanks ?",
    askTime: "2020-05-03T00:47:03.000000Z",
    answer: "Yes, it promotes the wellbeing of your skin, hair and nails.",
    answerTime: "2020-07-09 17:50:39",
  },
];

export default questionData;
