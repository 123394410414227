import React, { useContext, useState } from "react";
import { CartContext } from "./CartContext";
import "../css/checkout.scss";
import productData from "../Datas/productData";
import Header from "./common/Header";
import Footer from "./common/Footer";
import ReactFlagsSelect from "react-flags-select";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const CheckOut = () => {
  const navigate = useNavigate();
  const { cartItems } = useContext(CartContext);
  const [formData, setFormData] = useState({
    country: "GB",
    phone: "",
    email: "",
    firstName: "",
    lastName: "",
    city: "",
    zipCode: "",
    address: "",
    province: "",
    selected: "GB",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Email 格式验证
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  // Phone 格式验证
  const validatePhone = (phone) => {
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;
    return phoneRegex.test(phone);
  };

  const handlePlaceOrder = () => {
    const {
      email,
      firstName,
      lastName,
      address,
      city,
      zipCode,
      province,
      phone,
    } = formData;

    if (
      !email ||
      !firstName ||
      !lastName ||
      !address ||
      !city ||
      !zipCode ||
      !province ||
      !phone
    ) {
      alert("Please fill in all required fields.");
      return;
    }

    if (!validateEmail(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    if (!validatePhone(phone)) {
      alert("Please enter a valid phone number.");
      return;
    }

    alert("Order placed!");
    navigate("/paysuccess");
  };

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => {
      const product = getProductById(item.id);
      return total + parseFloat(product.price) * item.quantity;
    }, 0);
  };

  const calculateItemTotal = (itemId, quantity) => {
    const product = getProductById(itemId);
    if (!product) return 0;
    const price = parseFloat(product.price);
    return price * quantity;
  };
  return (
    <>
      <Header />
      <div className="check-custom-checkout-page">
        <div className="left col-big-6 col-mid-6 col-sm-12 col-xs-12">
          <div className="check-order-info">
            <div>
              <h2>Contact</h2>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email (for sending your order receipt):"
                value={formData.email}
                onChange={handleChange}
              />
            </div>

            <ReactFlagsSelect
              selected={formData.selected}
              onSelect={(code) => {
                setFormData((prevData) => ({
                  ...prevData,
                  selected: code,
                  country: code,
                }));
              }}
              className="check-select"
            />

            <div>
              <input
                type="text"
                id="firstName"
                name="firstName"
                placeholder="First name:"
                value={formData.firstName}
                onChange={handleChange}
              />
            </div>
            <div>
              <input
                type="text"
                id="lastName"
                name="lastName"
                placeholder="Last name:"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
            <div>
              <input
                type="text"
                id="zipCode"
                name="zipCode"
                placeholder="Zip code:"
                value={formData.zipCode}
                onChange={handleChange}
              />
            </div>
            <div>
              <input
                type="text"
                id="province"
                name="province"
                placeholder="Province:"
                value={formData.province}
                onChange={handleChange}
              />
            </div>
            <div>
              <input
                type="text"
                id="address"
                name="address"
                placeholder="Address:"
                value={formData.address}
                onChange={handleChange}
              />
            </div>
            <div>
              <input
                type="text"
                id="city"
                name="city"
                placeholder="City:"
                value={formData.city}
                onChange={handleChange}
              />
            </div>

            <PhoneInput
              country={formData.country.toLowerCase()}
              value={formData.phone}
              onChange={(number) => {
                setFormData((prevData) => ({
                  ...prevData,
                  phone: number,
                }));
              }}
              className="check-input"
              style={{ width: "100%" }}
            />

            <button
              className="check-checkout-button"
              onClick={handlePlaceOrder}
            >
              Pay Now
            </button>
          </div>
        </div>

        <div className="right col-big-6 col-mid-6 col-sm-12 col-xs-12">
          <div className="check-order-summary">
            <h2>Order Summary</h2>
            <div className="check-order-details">
              {cartItems.map((item, index) => {
                const product = getProductById(item.id);
                return (
                  <div key={index} className="check-cart-item">
                    <img src={product.images[0]} alt="Product" />
                    <div className="check-item-details">
                      <h3>{product.name}</h3>
                      <p>Price: {product.price}</p>
                      <p>Quantity: {item.quantity}</p>
                    </div>
                    <p>
                      £{calculateItemTotal(item.id, item.quantity).toFixed(2)}
                    </p>
                  </div>
                );
              })}

              <div className="check-summary-details">
                <div className="gridTotal">
                  <span className="grand">Grand Total:</span>
                  <span className="grand1">£{calculateTotal().toFixed(2)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CheckOut;
