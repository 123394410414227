import React, { useState, useEffect, useContext } from "react";
import "../css/product.scss";
import Header from "./common/Header";
import productData from "../Datas/productData";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import Footer from "./common/Footer";
import { CartContext } from "./CartContext";
import { useNavigate } from "react-router-dom";
import AlsoBuy from "./AlsoBuy";
import Story from "./common/Story";

function Product() {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slidesToShow, setSlidesToShow] = useState(5);
  const [selectedOption, setSelectedOption] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [openSections, setOpenSections] = useState({});
  const { addToCart } = useContext(CartContext);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 430) {
        setSlidesToShow(3);
      }
      if (window.innerWidth === 768) {
        setSlidesToShow(7);
      }
      if (window.innerWidth > 768) {
        setSlidesToShow(5);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleToggle = (id) => {
    setOpenSections((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };
  const handleIncrease = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };
  const handleDecrease = () => {
    setQuantity((prevQuantity) => Math.max(prevQuantity - 1, 1));
  };
  const handleDivClick = (option) => {
    setSelectedOption(option);
  };

  const handleCheckboxChange = (productId) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(productId)) {
        return prevSelectedItems.filter((id) => id !== productId);
      } else {
        return [...prevSelectedItems, productId];
      }
    });
  };

  const handleAddToCart = (id) => {
    addToCart(id, quantity);
    selectedItems.forEach((itemId) => {
      addToCart(itemId, 1);
    });
    navigate("/checkout");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const settings = {
    dots: false,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow,
    slidesToScroll: 1,
    initialSlide: 2,
  };

  return (
    <>
      <Header />
      <div className="container">
        <div className="row">
          {productData
            .filter((e) => e.id === 1)
            .map((shop, index) => (
              <div key={index} className="row_top ">
                <div className="row_top_inner">
                  <div className="row_top_inner_left col-big-6 col-mid-12 col-sm-12 col-xs-12">
                    <div className="row_top_inner_left_top">
                      {shop.images.map((image, index) => (
                        <img
                          key={index}
                          src={image}
                          alt={`${shop.name}`}
                          style={{
                            display: index === currentIndex ? "block" : "none",
                          }}
                        />
                      ))}
                    </div>
                    <div className="row_top_inner_left_bot">
                      <Slider {...settings}>
                        {shop.images.map((image, index) => (
                          <button
                            key={index}
                            onClick={() => setCurrentIndex(index)}
                            className={index === currentIndex ? "active" : ""}
                          >
                            <img src={image} alt={`${shop.name}`} />
                          </button>
                        ))}
                      </Slider>
                    </div>
                  </div>
                  <div className="row_top_inner_right col-big-6 col-mid-12 col-sm-12 col-xs-12">
                    <div className="row_top_inner_right_inner">
                      <div className="product-block--header">
                        <span className="nudge_label"> Selling Fast 🔥</span>
                        <h1 className="h2 product-single__title">
                          Collagen Powder
                        </h1>
                        <div
                          className="reviews-importify-element visible"
                          style={{ visibility: "visible" }}
                        >
                          <div className="reviews-importify-reviews-ratings">
                            <img alt="" src="/head/star.png" />
                            <span className="ri_review_count wellgard-font">
                              <u>Read 3628 verified reviews</u>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="product-block--body">
                        <div className="product-block--price">
                          <span className="product-block--price-pre">
                            Lek {shop.prePrice}
                          </span>
                          <span className="product-block--price-now">
                            £{selectedOption === "oneTime" ? "19.99" : "16.99"}
                          </span>
                          <div className="product__policies rte">
                            <font _mstmutation={1}>Tax included.</font>
                            <Link onClick={toTop} to="/shipping-policy">
                              Shipping
                            </Link>
                            <font _mstmutation={1}>
                              calculated at checkout.
                            </font>
                          </div>
                        </div>

                        <div className="product-block"></div>
                        <div className="product-block product-block--sales-point">
                          <ul className="sales-points">
                            <li className="sales-point">
                              <span className="icon-and-text">
                                <svg
                                  aria-hidden="true"
                                  focusable="false"
                                  role="presentation"
                                  className="icon icon-truck"
                                  viewBox="0 0 64 64"
                                >
                                  <defs>
                                    <style
                                      dangerouslySetInnerHTML={{
                                        __html: ".a{stroke-miterlimit:10}",
                                      }}
                                    />
                                  </defs>
                                  <path
                                    className="a"
                                    d="M16.5 43.22H6.88V16.5h33.14v26.72H23.94m21.48 0h-5.4V30.4h17.1v12.82h-4.23m4.23-12.82-6.41-8.56H40.02"
                                  />
                                  <circle
                                    className="a"
                                    cx="20.24"
                                    cy="43.76"
                                    r="3.74"
                                  />
                                  <path
                                    className="a"
                                    d="M45.42 43.23a3.82 3.82 0 1 1 0 .37v-.37"
                                  />
                                </svg>
                                <span>FREE UK DELIVERY OVER £25</span>
                              </span>
                            </li>
                          </ul>
                        </div>

                        <div className="product-block">
                          <div className="dw-moneyback-container">
                            <img
                              alt=""
                              className="dw-money-back-img"
                              src="https://cdn.shopify.com/s/files/1/0244/9460/4362/files/beating-hearts_192.gif?v=1649447728"
                              width="70px"
                              height="70px"
                              loading="lazy"
                            />
                            <div id="dw-signup-newsletter">
                              <b>Love it or get a full refund</b> <br />
                              within 30 days - even if the product
                              <br />
                              has been used.
                            </div>
                          </div>
                        </div>

                        <div className="product-block">
                          <span
                            style={{
                              padding: "5px 10px",
                              fontWeight: 600,
                              backgroundColor: "#edf4ec",
                              borderRadius: 5,
                              color: "#359235",
                              fontSize: 14,
                            }}
                            className="product_green"
                          >
                            👉 &nbsp;1 month supply, as low as £0.56 per day
                          </span>
                        </div>

                        <div className="product-block">
                          <div
                            className={`product_left_top ${
                              selectedOption === "oneTime" ? "selected" : ""
                            }`}
                            onClick={() => handleDivClick("oneTime")}
                            style={{
                              border:
                                selectedOption === "oneTime"
                                  ? "1px solid #273376"
                                  : "1px solid #d2d8e0",
                            }}
                          >
                            <div>
                              <input
                                type="radio"
                                className="product_left_top_input"
                                name="productOption"
                                id="product_3670667067466_subsave2"
                              />
                              <label
                                htmlFor="product_3670667067466_subsave2"
                                class="product_left_top_label"
                              >
                                <span
                                  class="rc-option__text"
                                  data-label-text-subsave=""
                                  _mstmutation="1"
                                >
                                  One-time purchase
                                </span>
                              </label>
                            </div>
                            <div class="product_left_top_price">£19.99</div>
                          </div>
                          <div
                            className={`product_left_top ${
                              selectedOption === "subscribe" ? "selected" : ""
                            }`}
                            onClick={() => handleDivClick("subscribe")}
                            style={{
                              border:
                                selectedOption === "subscribe"
                                  ? "1px solid #273376"
                                  : "1px solid #d2d8e0",
                            }}
                          >
                            <div>
                              <input
                                type="radio"
                                className="product_left_top_input"
                                name="productOption"
                                id="product_3670667067466_subsave1"
                              />
                              <label
                                htmlFor="product_3670667067466_subsave1"
                                class="product_left_top_label"
                              >
                                <font _mstmutation="1">
                                  <span
                                    class="rc-option__text"
                                    data-label-text-subsave=""
                                    _mstmutation="1"
                                  >
                                    Subscribe &amp; save
                                  </span>
                                  <span
                                    class="rc-option__discount rc_widget__option__discount"
                                    data-label-discount=""
                                    _mstmutation="1"
                                  >
                                    15%
                                  </span>
                                </font>
                              </label>
                            </div>
                            <div class="product_left_top_price">£16.99</div>
                          </div>
                          {selectedOption === "subscribe" && (
                            <div
                              className="product_left_top select_subscrib_box"
                              style={{
                                border: "1px solid #273376",
                                marginTop: "0",
                              }}
                            >
                              <img
                                alt=""
                                style={{
                                  width: "16px",
                                  height: "16px",
                                  margin: "0 10px 0 0",
                                }}
                                src="https://cdn.shopify.com/s/files/1/1361/9483/t/124/assets/icon-subscribe-arrow.svg?v=11981638797537464408"
                              />

                              <select
                                id="selling_plan_3670667067466"
                                className="rc_widget__option__plans__dropdown rc-selling-plans__dropdown"
                                name="selling_plan"
                                data-plans-dropdown=""
                                data-v-335eb9f1=""
                              >
                                <option
                                  data-plan-option="Delivery every 1 Month"
                                  value={4299227368}
                                  data-v-335eb9f1=""
                                >
                                  Delivery every 1 Month
                                </option>
                                <option
                                  data-plan-option="Delivery every 2 Months"
                                  value={4299325672}
                                  data-v-335eb9f1=""
                                >
                                  Delivery every 2 Months
                                </option>
                                <option
                                  data-plan-option="Delivery every 3 Months"
                                  value={4299391208}
                                  data-v-335eb9f1=""
                                >
                                  Delivery every 3 Months
                                </option>
                              </select>
                            </div>
                          )}
                        </div>

                        <div className="qty_add_flex">
                          <div className="product__quantity">
                            <div className="js-qty__wrapper">
                              <input
                                type="text"
                                id="Quantity-template--23914698146171__main3670667067466"
                                className="js-qty__num"
                                defaultValue={1}
                                min={1}
                                value={quantity}
                                aria-label="quantity"
                                pattern="[0-9]*"
                                form="AddToCartForm-template--23914698146171__main-3670667067466"
                                name="quantity"
                                _mstaria-label={122694}
                              />
                              <button
                                type="button"
                                className="js-qty__adjust js-qty__adjust--minus"
                                aria-label="Reduce item quantity by one"
                                onClick={handleDecrease}
                              >
                                <svg
                                  aria-hidden="true"
                                  focusable="false"
                                  role="presentation"
                                  className="icon icon-minus"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fill="#444"
                                    d="M17.543 11.029H2.1A1.032 1.032 0 0 1 1.071 10c0-.566.463-1.029 1.029-1.029h15.443c.566 0 1.029.463 1.029 1.029 0 .566-.463 1.029-1.029 1.029z"
                                  />
                                </svg>
                                <span
                                  className="icon__fallback-text"
                                  aria-hidden="true"
                                >
                                  −
                                </span>
                              </button>
                              <button
                                type="button"
                                className="js-qty__adjust js-qty__adjust--plus"
                                aria-label="Increase item quantity by one"
                                onClick={handleIncrease}
                              >
                                <svg
                                  aria-hidden="true"
                                  focusable="false"
                                  role="presentation"
                                  className="icon icon-plus"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fill="#444"
                                    d="M17.409 8.929h-6.695V2.258c0-.566-.506-1.029-1.071-1.029s-1.071.463-1.071 1.029v6.671H1.967C1.401 8.929.938 9.435.938 10s.463 1.071 1.029 1.071h6.605V17.7c0 .566.506 1.029 1.071 1.029s1.071-.463 1.071-1.029v-6.629h6.695c.566 0 1.029-.506 1.029-1.071s-.463-1.071-1.029-1.071z"
                                  />
                                </svg>
                                <span
                                  className="icon__fallback-text"
                                  aria-hidden="true"
                                >
                                  +
                                </span>
                              </button>
                            </div>
                          </div>
                          <button
                            type="submit"
                            name="add"
                            className="add-to-cart-btn"
                            style={{ visibility: "visible" }}
                            onClick={() => handleAddToCart(shop.id)}
                          >
                            <span
                              data-add-to-cart-text=""
                              data-default-text="Checkout"
                            >
                              Checkout
                            </span>
                          </button>
                        </div>

                        <div className="also_bought">
                          <div className="rebuy-widget-content">
                            <h4 className="primary-title">
                              Customers also bought 👇
                            </h4>
                            <div className="rebuy-addon__items">
                              {productData
                                .filter((e) => e.id >= 2 && e.id <= 3)
                                .map((item, index) => (
                                  <div
                                    key={index}
                                    className="rebuy-addon__item"
                                  >
                                    <div className="rebuy-addon__item-checkbox">
                                      <label className="rebuy-checkbox-label">
                                        <input
                                          type="checkbox"
                                          className="checkbox-input rebuy-checkbox"
                                          checked={selectedItems.includes(
                                            item.id
                                          )}
                                          onChange={() =>
                                            handleCheckboxChange(item.id)
                                          }
                                        />
                                      </label>
                                    </div>
                                    <div className="rebuy-addon__item-image">
                                      <img
                                        src={item.images[0]}
                                        alt="Beauty Gummies with Biotin & Zinc for Skin, Hair & Nails"
                                        data-extentions-extra-ocr-id="200db29e5a77f4a57e746b2281367deb"
                                      />
                                    </div>

                                    <div className="rebuy-addon__item-info">
                                      <div
                                        alt="View Beauty Gummies with Biotin & Zinc for Skin, Hair & Nails"
                                        className="rebuy-addon__item-product-title rebuy-product-title"
                                      >
                                        {item.name}
                                      </div>
                                      <div className="rebuy-addon__item-product-price rebuy-product-price">
                                        <div>
                                          <span className="rebuy-money">
                                            <span className="sr-only">
                                              Price
                                            </span>
                                            <span>£ {item.price}</span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>

                        <div className="product-block">
                          <small
                            style={{
                              display: "flex",
                              height: 24,
                              marginTop: 28,
                            }}
                          >
                            <span
                              style={{
                                borderRadius: 5,
                                paddingBottom: 3,
                                background: "rgb(243,33,161)",
                                color: "white",
                              }}
                            >
                              <strong>&nbsp; ⚠️ HURRY: LOW STOCK&nbsp;</strong>
                              &nbsp;
                            </span>
                          </small>
                        </div>

                        <div className="product-block product-block--sales-point">
                          <ul className="sales-points">
                            <li className="sales-point">
                              <span className="icon-and-text">
                                <svg
                                  aria-hidden="true"
                                  focusable="false"
                                  role="presentation"
                                  className="icon icon-package"
                                  viewBox="0 0 64 64"
                                >
                                  <path d="M32 54.52 10 41.95v-19.9L32 9.48l22 12.57v19.9L32 54.52z" />
                                  <path d="M32 54.52v-19.9l22-12.57M32 34.62 10 22.05m31.7-7.03L21 28.33v8.38" />
                                </svg>
                                <span>
                                  Easily pause, skip, or cancel subscription
                                  anytime.
                                </span>
                              </span>
                            </li>
                          </ul>
                        </div>

                        <div className="product-block product-block--sales-point">
                          <ul className="sales-points">
                            <li className="sales-point">
                              <span className="icon-and-text">
                                <svg
                                  aria-hidden="true"
                                  focusable="false"
                                  role="presentation"
                                  className="icon icon-lock"
                                  viewBox="0 0 64 64"
                                >
                                  <path d="M19.45 23.6v-3.2c0-7 5.89-11.75 12.55-11.75 7.21 0 12.55 5.49 12.55 11.75v3.2M10 23.6h44v31.75H10zm22 13.87v7.47" />
                                  <circle cx={32} cy="35.87" r="1.6" />
                                </svg>
                                <span>Secure transaction.</span>
                              </span>
                            </li>
                          </ul>
                        </div>

                        <div className="product-block">
                          Struggling to pick the right supplement?
                          <u>Take our quiz </u>.
                        </div>
                        <div className="product-block">
                          <p>
                            <strong>
                              Try the mighty good collagen everyone is raving
                              about.
                            </strong>
                          </p>
                          <p>
                            <strong>
                              "
                              <em>
                                I have been using this collagen for 8 months.
                                I’m 38 and this has truly changed my complexion!
                                It's worth every penny!
                              </em>
                              "
                            </strong>
                          </p>
                        </div>
                        <p>
                          Nutritionists will tell you they love Wellgard
                          Collagen Powder for its many benefits, including being
                          hydrolysed for better absorption and containing high
                          levels of amino acids. 😍
                        </p>
                        <p>What they mean is it...Uh oh, it works indeed. 😉</p>
                        <p>
                          And it works so well you'll start singing, "I
                          feeeeeeeeel good, I knew that I would, now. I
                          feeeeeeeeel nice, like sugar and spice". 🎵
                        </p>
                        <p>
                          You see, it's true that there are many things in life
                          you can't control. 🤷‍♀️
                        </p>
                        <p>
                          But Wellgard collagen powder is formulated to help you
                          focus on things you CAN change. 💪
                        </p>
                        <p>So you can live unapologetically free. Free to:</p>
                        <ul className="product_right_ul">
                          <li>
                            Embrace your sexiness. By keeping your skin hydrated
                            and glowing like a youthful Adele. 💄
                          </li>
                          <li>
                            Pamper yourself. By keeping your hair &amp; nails
                            always on point. 💅
                          </li>
                          <li>
                            Be yourself and love yourself again. By staying
                            healthy and caring for your joints. 💪
                          </li>
                          <li>
                            Cultivate good vibes. By keeping a healthy gut,
                            because your gut health is connected to everything.
                            Your immune system, mood, skin, and mental health.
                            🧠
                          </li>
                        </ul>
                        <p>
                          Each 400g tub of our collagen powder will last you 31
                          days. 📅
                        </p>
                        <p>
                          And with our subscription plan you can say Ta ta to
                          all those sticky notes all over the house reminding
                          you when to buy more collagen. All you have to do is
                          to select your delivery frequency (every 30, 60 or 90
                          days). 📦
                        </p>
                        <p>
                          Of course, you can always buy our collagen powder as a
                          one-off purchase. But as a bonus when you subscribe,
                          you save 15%. 💰
                        </p>
                        <p>
                          So If you're considering adding our Collagen powder to
                          your wellness routine for at least three months,
                          you'll end up saving £9 (You'll pay £50.97 instead of
                          £59.97). 🍾
                        </p>
                        <p>
                          Might not sound that much, but £9 buys you a very
                          decent bottle of Rosé, indeed. 🍷
                        </p>
                      </div>

                      <div className="product-block product-block--tab">
                        <div className="collapsibles-wrapper collapsibles-wrapper--border-bottom">
                          <button
                            type="button"
                            className="label collapsible-trigger collapsible-trigger-btn collapsible-trigger-btn--borders collapsible--auto-height"
                            aria-controls="Product-content-5d3678f4-f732-4c61-8d2c-7e0f0d4730713670667067466"
                            aria-expanded="false"
                            onClick={() => handleToggle("section-1")}
                          >
                            Detailed Description
                            <span
                              className={`collapsible-trigger__icon collapsible-trigger__icon--open ${
                                openSections["section-1"] ? "rotated" : ""
                              }`}
                              role="presentation"
                            >
                              <svg
                                aria-hidden="true"
                                focusable="false"
                                role="presentation"
                                className="icon icon--wide icon-chevron-down"
                                viewBox="0 0 28 16"
                              >
                                <path
                                  d="m1.57 1.59 12.76 12.77L27.1 1.59"
                                  strokeWidth={2}
                                  stroke="#000"
                                  fill="none"
                                />
                              </svg>
                            </span>
                          </button>
                          <div
                            id="Product-content-5d3678f4-f732-4c61-8d2c-7e0f0d4730713670667067466"
                            className={`collapsible-content collapsible-content--all ${
                              openSections["section-1"]
                                ? "visible is-open"
                                : "hidden"
                            }`}
                          >
                            <div className="collapsible-content__inner rte">
                              <p>
                                ★ For people looking for a collagen powder that
                                does the job. And tastes and smells neutral, not
                                like sink water and fish pee.
                              </p>
                              <p>
                                ★ For people who know that consistency is
                                everything. And this collagen powder in your fav
                                hot or cold beverage every morning has your skin
                                lookin RIGHT. Like your queen Cheryl.
                              </p>
                              <p>
                                ★ For people who want to say NOOOO to bad hair
                                days. And want to say YAASSSS to hair that feels
                                stronger, healthier and softer than silk.
                              </p>
                              <p>
                                ★ For people who know that collagen won't change
                                their age in people or dog years. But know it
                                will make your joints less stiff and achy.
                              </p>
                              <p>
                                ★ For people who want to be able to have lovely
                                long and strong nails that make you feel a bit
                                more glam.Warning: Not suitable for Vegetarians
                                or Vegans. But suitable for Halal, Kosher and
                                Keto diets.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="collapsibles-wrapper collapsibles-wrapper--border-bottom">
                          <button
                            type="button"
                            className="label collapsible-trigger collapsible-trigger-btn collapsible-trigger-btn--borders collapsible--auto-height"
                            aria-controls="Product-content-5d3678f4-f732-4c61-8d2c-7e0f0d4730713670667067466"
                            aria-expanded="false"
                            onClick={() => handleToggle("section-2")}
                          >
                            Full Ingredients
                            <span
                              className={`collapsible-trigger__icon collapsible-trigger__icon--open ${
                                openSections["section-2"] ? "rotated" : ""
                              }`}
                              role="presentation"
                            >
                              <svg
                                aria-hidden="true"
                                focusable="false"
                                role="presentation"
                                className="icon icon--wide icon-chevron-down"
                                viewBox="0 0 28 16"
                              >
                                <path
                                  d="m1.57 1.59 12.76 12.77L27.1 1.59"
                                  strokeWidth={2}
                                  stroke="#000"
                                  fill="none"
                                />
                              </svg>
                            </span>
                          </button>
                          <div
                            id="Product-content-5d3678f4-f732-4c61-8d2c-7e0f0d4730713670667067466"
                            className={`collapsible-content collapsible-content--all ${
                              openSections["section-2"]
                                ? "visible is-open"
                                : "hidden"
                            }`}
                          >
                            <div className="collapsible-content__inner rte">
                              <p>Bovine collagen peptides powder.</p>
                            </div>
                          </div>
                        </div>
                        <div className="collapsibles-wrapper collapsibles-wrapper--border-bottom">
                          <button
                            type="button"
                            className="label collapsible-trigger collapsible-trigger-btn collapsible-trigger-btn--borders collapsible--auto-height"
                            aria-controls="Product-content-5d3678f4-f732-4c61-8d2c-7e0f0d4730713670667067466"
                            aria-expanded="false"
                            onClick={() => handleToggle("section-3")}
                          >
                            Directions
                            <span
                              className={`collapsible-trigger__icon collapsible-trigger__icon--open ${
                                openSections["section-3"] ? "rotated" : ""
                              }`}
                              role="presentation"
                            >
                              <svg
                                aria-hidden="true"
                                focusable="false"
                                role="presentation"
                                className="icon icon--wide icon-chevron-down"
                                viewBox="0 0 28 16"
                              >
                                <path
                                  d="m1.57 1.59 12.76 12.77L27.1 1.59"
                                  strokeWidth={2}
                                  stroke="#000"
                                  fill="none"
                                />
                              </svg>
                            </span>
                          </button>
                          <div
                            id="Product-content-5d3678f4-f732-4c61-8d2c-7e0f0d4730713670667067466"
                            className={`collapsible-content collapsible-content--all ${
                              openSections["section-3"]
                                ? "visible is-open"
                                : "hidden"
                            }`}
                          >
                            <div className="collapsible-content__inner rte">
                              <p>
                                Stir 2 tablespoons into a large cup of water,
                                tea, coffee or juice or add to foods such as
                                porridge, soups and smoothies.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>{" "}
        <AlsoBuy />
        <div className="row_three">
          <h4 class="primary-title">
            <h2>🔥 Recently purchased by others</h2>
          </h4>
          <div className="rebuy_product_grid">
            {productData
              .filter((e) => e.id >= 6 && e.id <= 9)
              .map((product, index) => (
                <div
                  className="rebuy-product-block col-big-3 col-mid-3 col-sm-6 col-xs-6"
                  key={index}
                >
                  <div className="rebuy-product-media">
                    <img
                      loading="eager"
                      src={product.images[0]}
                      alt={product.name}
                    />
                  </div>

                  <div className="rebuy-product-info">
                    <div className="rebuy-product-title clickable">
                      {product.name}
                    </div>
                    <div className="rebuy-product-price">
                      <div>
                        <span className="rebuy-money">
                          <span tabIndex={0}>£{product.price}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="rebuy-product-actions">
                    <button
                      aria-label="Add the product, Gutstar 20 Billion CFU Probiotics For Gut Health to Cart"
                      type="button"
                      className="rebuy-button"
                      onClick={() => handleAddToCart(product.id)}
                    >
                      <span>
                        <b>Checkout</b>
                      </span>
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="slideshow-wrapper">
          <img
            src="https://wellgard.co.uk/cdn/shop/files/collagen1.jpg?v=1704898020&width=2400"
            alt="Collagen Powder"
            className="hero__image"
          />
          <div className="hero__text-wrap">
            <div className="page-width">
              <div className="hero__text-content vertical-bottom horizontal-center">
                <h2>Drink it. Eat it. Just don't forget taking it.</h2>
                <p>OVER 2 MILLION+ DOSES SOLD</p>
              </div>
            </div>
          </div>
        </div>
        <div className="shopify-section index-section story_box_one ">
          <div className="page-width aos-init aos-animate">
            <div className="section-header">
              <h2 className="section-header__title">
                You may have seen us featured in...
              </h2>
            </div>
            <div className="logo-bar logo-bar--template--23914698146171__1640899583b77ab63b">
              <div className="logo-bar__item">
                <img
                  src="https://wellgard.co.uk/cdn/shop/files/featured_0000_dailymail.png?v=1640901499&width=180"
                  alt=""
                  loading="lazy"
                  className="logo-bar__image image-element"
                  sizes="(min-width: 769px) 160px, 110px"
                />
              </div>
              <div className="logo-bar__item">
                <img
                  src="https://wellgard.co.uk/cdn/shop/files/featured_0001_independent.png?v=1640901518&width=180"
                  alt=""
                  loading="lazy"
                  className="logo-bar__image image-element"
                  sizes="(min-width: 769px) 160px, 110px"
                />
              </div>
              <div className="logo-bar__item">
                <img
                  src="https://wellgard.co.uk/cdn/shop/files/featured_0004_cosmo.png?v=1640901539&width=180"
                  alt=""
                  loading="lazy"
                  className="logo-bar__image image-element"
                  sizes="(min-width: 769px) 160px, 110px"
                />
              </div>
              <div className="logo-bar__item">
                <img
                  src="https://wellgard.co.uk/cdn/shop/files/featured_0005_menshealth.png?v=1640901553&width=180"
                  alt=""
                  loading="lazy"
                  className="logo-bar__image image-element"
                  sizes="(min-width: 769px) 160px, 110px"
                />
              </div>
              <div className="logo-bar__item">
                <img
                  src="https://wellgard.co.uk/cdn/shop/files/featured_0002_sundayexpress.png?v=1640901570&width=180"
                  alt=""
                  loading="lazy"
                  className="logo-bar__image image-element"
                  sizes="(min-width: 769px) 160px, 110px"
                />
              </div>
              <div className="logo-bar__item">
                <img
                  src="https://wellgard.co.uk/cdn/shop/files/featured_0003_elle.png?v=1640901593&width=180"
                  alt=""
                  loading="lazy"
                  className="logo-bar__image image-element"
                  sizes="(min-width: 769px) 160px, 110px"
                />
              </div>
            </div>
          </div>
        </div>
        <Story />
      </div>
      <Footer />
    </>
  );
}

export default Product;
