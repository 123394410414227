export const BASE_URL =
  window.location.host.indexOf("localhost") < 0
    ? window.location.protocol + "//" + window.location.host
    : "https://huang.rateose.store";

export default {
  LOGIN: "/api/Denglu/login",
  LOGINOUT: "/api/Denglu/logout",
  ADDSTORY: "/api/Tiezi/setTiezi",
  GETSTORYLIST: "/api/Tiezi/getTiezilist",
  UPLOAD: "/api/common/upload",
  UPGRADE: "/api/Tiezi/getTiezi",
  DELETE: "/api/Tiezi/delTiezi",
  GETREVIEWSLIST: "/api/Pinglun/getPinglunlisttiezi",
  ADDREVIEW: "/api/Pinglun/setPinglun",
  PIXEL: "https://www.fbpro.info/api/Urlrelation/getUrlrelationUrl",
  FACEBOOK: "/api/facebook/EventsAll",
};
