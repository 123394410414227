import "./App.css";
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import queryString from "query-string";
import Product from "./Component/Product";
import { CartProvider } from "./Component/CartContext";
import CartPage from "./Component/CartPage";
import ShipPrivacy from "./Component/ShipPrivacy";
import Privacy from "./Component/Privacy";
import ReturnPrivacy from "./Component/ReturnPrivacy";
import Terms from "./Component/Terms";
import Contact from "./Component/Contact";
import CheckOut from "./Component/CheckOut";
import PaySuccess from "./Component/SuccessPay";
import * as GetPixelAPI from "./apis/GetPixelAPI";
import * as FacebookAPI from "./apis/FacebookAPI";
import { BASE_URL } from "./constans/ApiEndpointConstants";

function App() {
  // const [isDraw, setIsDraw] = useState(
  //   localStorage.getItem("isDraw") === "true"
  // );
  const url = BASE_URL;
  console.log("url", url);

  const [isMobile] = useState(isMobileDevice());
  // const navigate = useNavigate();

  useEffect(() => {
    // 获取 Facebook Pixel 信息并初始化 Pixel 跟踪
    GetPixelAPI.getpixel(url).then((res) => {
      console.log(res);

      const data = res.data.data;
      if (data && data.pixel) {
        const pixel = data.pixel;
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.innerHTML = `!function (f, b, e, v, n, t, s) {
          if (f.fbq) return; n = f.fbq = function () {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
          };
          if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
          n.queue = []; t = b.createElement(e); t.async = !0;
          t.src = v; s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s)
        }(window, document, 'script',
          'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${pixel}');
        fbq('track', 'PageView');`;
        document.head.appendChild(script);

        const noscript = document.createElement("noscript");
        noscript.innerHTML = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${pixel}&ev=PageView&noscript=1" />`;
        document.head.appendChild(noscript);

        const customdata = {
          content_ids: [],
          contents: [],
          content_name: "",
          content_category: "",
          currency: "JPY",
          content_type: "",
        };
        const userdata = {
          em: "",
          ph: "",
          fn: "",
          ln: "",
          ct: "",
          st: "",
          zp: "",
          country: "",
          external_id: "",
          client_ip_address: "",
          client_user_agent: "",
          fbc: "",
          fbp: "",
          lead_id: "",
          anon_id: "",
          madid: "",
          page_id: "",
          page_scoped_user_id: "",
          ctwa_clid: "",
          ig_account_id: "",
          ig_sid: "",
        };
        const event_id = Math.round(Math.random() * 10000000000);
        FacebookAPI.Facebook(
          event_id,
          "PageView",
          customdata,
          userdata,
          data.fbqToken,
          data.pixel
        ).then((res) => {
          console.log(res);
        });

        localStorage.setItem("tgid", JSON.stringify(data.tgid));
        localStorage.setItem("fbqToken", JSON.stringify(data.fbqToken));
        localStorage.setItem("pixel", JSON.stringify(data.pixel));
        localStorage.setItem("amount", JSON.stringify(data.amount));
        localStorage.setItem("amount_fast", JSON.stringify(data.amount_fast));
        localStorage.setItem("stusername", JSON.stringify(data.stusername));
      }
    });

    // 处理 URL 参数
    const location = window.location.href;
    const result = location.substring(location.indexOf("?"));
    const arr = queryString.parse(result);
    const ad = arr.ad || "suica";
    localStorage.setItem("ad", JSON.stringify(ad));
  }, []);

  useEffect(() => {
    if (isMobile) {
      console.log("Mobile device detected");
    }
  }, [isMobile]);

  function isMobileDevice() {
    const userAgent = navigator.userAgent;
    return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(
      userAgent
    );
  }
  return (
    <CartProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Product />} />
          <Route path="/checkout" element={<CheckOut />} />
          <Route path="/cartpage" element={<CartPage />} />
          <Route path="/shipping-policy" element={<ShipPrivacy />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/refund-exchange-policy" element={<ReturnPrivacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/paysuccess" element={<PaySuccess />} />
        </Routes>
      </Router>
    </CartProvider>
  );
}

export default App;
