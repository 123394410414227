import React, { useContext } from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import "../css/privacy.scss";
import { CartContext } from "./CartContext";
import { Link, useNavigate } from "react-router-dom";
import productData from "../Datas/productData";

function ShipPrivacy() {
  const { addToCart } = useContext(CartContext);
  const navigate = useNavigate();

  const handleAddToCart = (id) => {
    addToCart(id, 1);

    navigate("/cartpage");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Header />
      <div className="privacy_box">
        <section
          id="shopify-section-template--23914697458043__main"
          className="shopify-section"
        >
          <div className="page-width page-width--narrow page-content">
            <header className="section-header">
              <h1 className="section-header__title">Shipping Policy</h1>
            </header>
            <div className="rte rte--nomargin">
              <meta charSet="utf-8" />
              <meta charSet="utf-8" />
              <p>
                Thank you for visiting and shopping at Wellgard.co.uk. Following
                are the terms and conditions that constitute our Shipping
                Policy.
              </p>
              <p>
                <b>Shipment processing time</b>
              </p>
              <p>
                All orders are processed within 1-2 business days. Orders are
                not shipped or delivered on weekends or holidays.
              </p>
              <p>
                If we are experiencing a high volume of orders, shipments may be
                delayed by a few days. Please allow additional days in transit
                for delivery. If there will be a significant delay in shipment
                of your order, we will contact you via email or telephone.
              </p>
              <p>
                <b>Shipment confirmation &amp; Order tracking</b>
              </p>
              <p>
                You will receive a Shipment Confirmation email once your order
                has shipped containing your tracking number(s). The tracking
                number will be active within 24 hours.
              </p>
              <h2>UK Shipping rates &amp; delivery estimates</h2>
              <p>
                Shipping charges for your order will be calculated and displayed
                at checkout.
              </p>
              <div className="table-wrapper">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <p>
                          <b>Shipment method</b>
                        </p>
                      </td>
                      <td>
                        <p>
                          <b>Estimated delivery time</b>
                        </p>
                      </td>
                      <td>
                        <p>
                          <b>Shipment cost</b>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>Royal Mail Standard</p>
                      </td>
                      <td>
                        <p>3-7 business days</p>
                      </td>
                      <td>
                        <p>£2.90</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>Royal Mail Expedited</p>
                      </td>
                      <td>
                        <p>2-3 business days</p>
                      </td>
                      <td>
                        <p>£3.99</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p>&nbsp;</p>
              <p>
                Delivery delays can occasionally occur. We cannot guarantee
                Royal Mail's recommended times and cannot take responsibility if
                orders take longer than the days stated.
              </p>
              <p>&nbsp;</p>
              <h2>
                North America International Shipping rates &amp; delivery
                estimates
              </h2>
              <p>
                Shipping charges for your order will be calculated and displayed
                at checkout.
              </p>
              <div className="table-wrapper">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <p>
                          <b>Shipment method</b>
                        </p>
                      </td>
                      <td>
                        <p>
                          <b>Estimated delivery time</b>
                        </p>
                      </td>
                      <td>
                        <p>
                          <b>Shipment cost</b>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>Royal Mail International Standard</p>
                      </td>
                      <td>
                        <p>7-14 business days</p>
                      </td>
                      <td>
                        <p>£17.99</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p>&nbsp;</p>
              <p>
                <b>Customs, Duties and Taxes</b>
              </p>
              <p>
                Wellgard.co.uk is not responsible for any customs and taxes
                applied to your order. All fees imposed during or after shipping
                are the responsibility of the customer (tariffs, taxes, etc.).
              </p>
              <p>
                Delivery delays can occasionally occur. We cannot guarantee
                Royal Mail's recommended times and cannot take responsibility if
                orders take longer than the days stated.
              </p>
              <h2>Ireland Shipping rates &amp; delivery estimates</h2>
              <p>
                Shipping charges for your order will be calculated and displayed
                at checkout.
              </p>
              <div className="table-wrapper">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <p>
                          <b>Shipment method</b>
                        </p>
                      </td>
                      <td>
                        <p>
                          <b>Estimated delivery time</b>
                        </p>
                      </td>
                      <td>
                        <p>
                          <b>Shipment cost</b>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>Standard Shipping</p>
                      </td>
                      <td>
                        <p>3-7 business days</p>
                      </td>
                      <td>
                        <p>£14.25</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>&nbsp;</p>
                      </td>
                      <td>
                        <p>&nbsp;</p>
                      </td>
                      <td>
                        <p>&nbsp;</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p>&nbsp;</p>
              <p>Delivery delays can occasionally occur.</p>
              <h2>Rest of the World Shipping rates &amp; delivery estimates</h2>
              <p>
                We deliver to the United Arab Emirates, South Africa, Australia,
                New Zealand, Indonesia, Jordan, Kuwait, Malaysia, Oman, Qatar,
                Saudi Arabia, Singapore, Bahrain, China, Hong Kong, Maldives,
                Philippines and Russia.
              </p>
              <p>
                Shipping charges for your order will be calculated and displayed
                at checkout.
              </p>
              <div className="table-wrapper">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <p>
                          <b>Shipment method</b>
                        </p>
                      </td>
                      <td>
                        <p>
                          <b>Estimated delivery time</b>
                        </p>
                      </td>
                      <td>
                        <p>
                          <b>Shipment cost</b>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>Royal Mail International Standard</p>
                      </td>
                      <td>
                        <p>7-14 business days</p>
                      </td>
                      <td>
                        <p>£17.99</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p>&nbsp;</p>
              <p>
                <b>Customs, Duties and Taxes</b>
              </p>
              <p>
                Wellgard.co.uk is not responsible for any customs and taxes
                applied to your order. All fees imposed during or after shipping
                are the responsibility of the customer (tariffs, taxes, etc.).
              </p>
              <p>
                Delivery delays can occasionally occur. We cannot guarantee
                Royal Mail's recommended times and cannot take responsibility if
                orders take longer than the days stated.
              </p>
              <h2>Returns Policy</h2>
              <p>
                Our<span>&nbsp;</span>
                <Link onClik={toTop} to="/refund-exchange-policy">
                  Return &amp; Refund Policy
                </Link>
                <span>&nbsp;</span>provides detailed information about options
                and procedures for returning your order.
              </p>
              <h3>CONTACT INFORMATION</h3>
              <p>
                Our Customer Service team is<span>&nbsp;</span>
                <strong>
                  available five days per week.<span>&nbsp;</span>
                </strong>
                We are open from 9 am to 5 pm on Monday to Friday. A full list
                of contact options can be found below.
              </p>
              <ul>
                <li>
                  <strong>Contact Customer Support</strong>
                  <span>
                    &nbsp;– Question about our products or your order? Then call
                    us for free on&nbsp;01273 604035
                  </span>
                </li>
                <li>
                  <strong>Customer Support Email</strong>
                  <span>
                    &nbsp;- You can email your queries to support@wellgard.co.uk
                  </span>
                </li>
                <li>
                  <strong>Write to Us</strong>
                  <span>
                    &nbsp;- Unit 9 Westergate Business Centre, Moulsecoomb,
                    Brighton, BN2 4QN
                  </span>
                </li>
              </ul>
              <p>
                <span>
                  Wellgard&nbsp;is a trading name of&nbsp;
                  <Link onClick={toTop} to="/" title="Bayagan Group Ltd">
                    Bayagan Group Ltd
                  </Link>
                  . Registered office: The Old Casino, 28 Fourth Avenue, Hove,
                  England, BN3 2PJ. Registered in England and Wales: Company
                  number 10271296. Registered VAT number 248023422.
                </span>
              </p>
              <div className="main">
                <section className="section section--page page">
                  <div className="container container--tiny" />
                </section>
              </div>
            </div>
          </div>
        </section>

        <div
          id="shopify-section-template--23914697458043__1636051971197314b2"
          className="shopify-section index-section"
        >
          <div className="text-left page-width page-width--narrow">
            <div className="theme-block">
              <h2>WE ARE HERE FOR YOU</h2>
            </div>
            <div className="theme-block">
              <div className="rte">
                <p>
                  If you want to find out more about us and our products, please
                  feel free to get in touch.
                </p>
                <p>
                  Our Customer Service team is&nbsp;
                  <strong>available five&nbsp;days per week.&nbsp;</strong>We
                  are open from 9 am to 5 pm on Monday to Friday. A full list of
                  contact options can be found below.
                </p>
                <p>
                  <strong>Contact Customer Support</strong>&nbsp;– Question
                  about our products or your order? Then call us for free
                  on&nbsp;01273 604035
                </p>
                <p>
                  <strong>Customer Support Email</strong>&nbsp;- You can email
                  your queries to support@wellgard.co.uk
                </p>
                <p>
                  <strong>Write to Us</strong>&nbsp;-&nbsp; Unit 9 Westergate
                  Business Centre, Moulsecoomb, Brighton, BN2 4QN
                </p>
                <p>
                  Wellgard is a trading name of&nbsp;
                  <Link onClick={toTop} to="/">
                    Bayagan Group Ltd
                  </Link>
                  . Registered office: The Old Casino, 28 Fourth Avenue, Hove,
                  England, BN3 2PJ. Registered in England and Wales: Company
                  number 10271296. Registered VAT number 248023422.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div
            className="row_three"
            style={{ maxWidth: "1300px", margin: "0 auto" }}
          >
            <h4 class="primary-title">
              <h2>Shop our best-sellers</h2>
            </h4>
            <div className="rebuy_product_grid">
              {productData
                .filter((e) => [1, 2, 3, 10].includes(e.id))
                .map((product, index) => (
                  <div
                    className="rebuy-product-block col-big-3 col-mid-3 col-sm-6 col-xs-6"
                    key={index}
                  >
                    <div className="rebuy-product-media">
                      <img
                        loading="eager"
                        src={product.images[0]}
                        alt={product.name}
                      />
                    </div>

                    <div className="rebuy-product-info">
                      <div className="rebuy-product-title clickable">
                        {product.name}
                      </div>
                      <div className="rebuy-product-price">
                        <div>
                          <span
                            className={`rebuy-money ${
                              product.prePrice ? "money_red" : ""
                            }`}
                          >
                            <span tabIndex={0}>£{product.price}</span>
                          </span>
                          {product.prePrice && (
                            <span class="rebuy-money compare-at">
                              <span>£{product.prePrice}</span>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="rebuy-product-actions">
                      <button
                        aria-label="Add the product, Gutstar 20 Billion CFU Probiotics For Gut Health to Cart"
                        type="button"
                        className="rebuy-button"
                        onClick={() => handleAddToCart(product.id)}
                      >
                        <span>
                          <b>Add to Cart</b>
                        </span>
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ShipPrivacy;
