import React, { useState, useRef } from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import "../css/privacy.scss";
import { Link } from "react-router-dom";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const indexSectionRef = useRef(null);

  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handlContact = (e) => {
    e.preventDefault();
    if (!name || !email || !msg) {
      alert("Please fill all fields!");
      return;
    }
    setName("");
    setEmail("");
    setMsg("");
    setSuccessMessage(true);
    if (indexSectionRef.current) {
      indexSectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      window.scrollBy(0, -30);
    }
  };
  return (
    <>
      <Header />
      <div className="privacy_box">
        <div
          id="shopify-section-template--23914697195899__ffd409d7-ceb7-4746-80ca-a902a1aa068f"
          className="shopify-section"
        >
          <div className="index-section">
            <div className="page-width">
              <div className="custom-content">
                <div className="custom__item one-whole align--center">
                  <div className="custom__item-inner custom__item-inner--liquid">
                    <div className="rte">
                      <h4>
                        We are currently experiencing an unprecedented surge in
                        demand for our products, which is impacting the
                        following operations:
                      </h4>
                      <ol>
                        <li>
                          <p>
                            <strong>Dispatch Time:</strong> Due to the
                            significantly high volume of daily orders, we are
                            facing challenges in dispatching orders within our
                            preferred timeframes of same day or next day. At
                            present, our average dispatch time ranges from 3 to
                            4 working days after receiving your order.
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>Email Response Time</strong>: Our usual
                            response time to emails is between 4 to 12 hours.
                            However, we are currently unable to meet this
                            timeframe. We are committed to replying to all
                            emails within 3 to 4 working days.
                          </p>
                        </li>
                        <li>
                          <p>
                            <strong>Phone Calls</strong>: Typically, we strive
                            to answer phone calls within 3 rings. However, due
                            to our entire team being actively involved in
                            packing customer orders, we are unable to meet this
                            target at the moment.
                          </p>
                        </li>
                      </ol>
                      <p>
                        We want to assure you are in good hands, and we will
                        dispatch your order at the earliest opportunity. We are
                        implementing measures to restore our services to normal
                        levels. To address the delays in order dispatch and
                        improve responsiveness to customer support queries, we
                        are actively hiring new team members. If your query is
                        not urgent, we kindly ask for your patience. However, if
                        your query is extremely urgent, please feel free to
                        email or call us.
                      </p>
                      <p>Thank you for your understanding and support.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section
          id="shopify-section-template--23914697195899__main"
          className="shopify-section"
        >
          <div className="page-width page-width--narrow page-content">
            <header className="section-header">
              <h1 className="section-header__title">Contact Us</h1>
            </header>
            <div className="rte rte--nomargin">
              <meta charSet="utf-8" />
              <h1>TALK TO US, WE WANT TO HELP YOU</h1>
              <p>
                You’re not going to&nbsp;experience a<span>&nbsp;</span>long
                phone menu when you call us. Your email isn’t going to be “lost”
                in our inbox. We are a small business, so we try harder to
                please our customers! We&nbsp;are fanatical about customer
                service and strive to&nbsp;provide the best customer&nbsp;care
                <span>&nbsp;</span>possible with every contact.&nbsp;
              </p>
              <h3>CUSTOMER SERVICE CONTACT OPTIONS</h3>
              <p>
                Our Customer Service team is&nbsp;
                <strong>
                  available five&nbsp;days per week.<span>&nbsp;</span>
                </strong>
                We are open from 9 am to 5 pm on Monday to Friday. A full list
                of contact options can be found below.
              </p>
              <ul>
                <li>
                  <strong>Contact Customer Support</strong>
                  <span>&nbsp;</span>– Question about our products or your
                  order? Then call us for free on<span>&nbsp;</span>01273 604035
                </li>
                <li>
                  <strong>Contact Form</strong>
                  <span>&nbsp;</span>- Complete the form below and we’ll respond
                  as quickly as we can (usually within 4 hours):
                </li>
                <li>
                  <strong>Write to Us</strong>
                  <span>&nbsp;</span>-&nbsp; Unit 9 Westergate Business Centre,
                  Moulsecoomb, Brighton, BN2 4QN
                </li>
              </ul>
              <div className="main">
                <section className="section section--page page">
                  <div className="container container--tiny">
                    <div className="page__content rte">
                      Wellgard is a trading name of Bayagan Group Ltd.
                      Registered office: The Old Casino, 28 Fourth Avenue, Hove,
                      England, BN3 2PJ. Registered in England and Wales: Company
                      number 10271296. Registered VAT number 248023422.
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>

        <div
          id="shopify-section-template--23914697195899__form"
          className="shopify-section index-section"
          ref={indexSectionRef}
        >
          <div className="index-section">
            <div className="page-width page-width--narrow">
              <div className="section-header">
                <h2 className="section-header__title">Contact us</h2>
              </div>
              <div className="form-vertical">
                <div
                  id="contact-template--23914697195899__form"
                  className="contact-form ng-pristine ng-valid"
                >
                  {successMessage && (
                    <p className="note note--success">
                      Thanks for contacting us. We'll get back to you as soon as
                      possible.
                    </p>
                  )}

                  <div className="grid grid--small">
                    <div className="grid__item medium-up--one-half">
                      <label htmlFor="ContactFormName-template--23914697195899__form">
                        Name
                      </label>
                      <input
                        type="text"
                        id="ContactFormName-template--23914697195899__form"
                        className="input-full"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        autoCapitalize="words"
                        defaultValue=""
                      />
                    </div>
                    <div className="grid__item medium-up--one-half">
                      <label htmlFor="ContactFormEmail-template--23914697195899__form">
                        Email
                      </label>
                      <input
                        type="email"
                        id="ContactFormEmail-template--23914697195899__form"
                        className="input-full"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        autoCorrect="off"
                        autoCapitalize="off"
                        defaultValue=""
                      />
                    </div>
                  </div>
                  <label htmlFor="ContactFormMessage-template--23914697195899__form">
                    Message
                  </label>
                  <textarea
                    rows={5}
                    id="ContactFormMessage-template--23914697195899__form"
                    className="input-full"
                    name="msg"
                    value={msg}
                    onChange={(e) => setMsg(e.target.value)}
                    defaultValue={""}
                  />

                  <button
                    type="submit"
                    id="contact-form-submit-template--23914697195899__form"
                    className="btn"
                    onClick={handlContact}
                  >
                    Send
                  </button>
                  <p data-spam-detection-disclaimer="">
                    This site is protected by hCaptcha and the hCaptcha{" "}
                    <Link onClick={toTop} to="/privacy-policy">
                      Privacy Policy
                    </Link>{" "}
                    and{" "}
                    <Link onClick={toTop} to="/terms">
                      Terms of Service
                    </Link>{" "}
                    apply.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
