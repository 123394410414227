import React, { useState, useEffect, useContext } from "react";
import "../common/style/head.scss";
import { Link } from "react-router-dom";
import { CartContext } from "../CartContext";

function Header() {
  const [isFixed, setIsFixed] = useState(false);
  const { cartItems } = useContext(CartContext);

  // 滚动事件监听
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 150) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="head_container">
      <div className="head_top">
        <span>Don't Miss Out! Free UK Shipping £25+ 🚚 🛍️</span>
      </div>

      <div className={`head_bot ${isFixed ? "fixed" : ""}`}>
        <div className="head_bot_inner">
          <div className="head_bot_inner_left">
            <div className="site-nav">
              <Link to="/" onClick={toTop}>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="presentation"
                  className="icon icon-hamburger"
                  viewBox="0 0 64 64"
                >
                  <title>icon-hamburger</title>
                  <path d="M7 15h51M7 32h43M7 49h51" />
                </svg>
              </Link>
            </div>
          </div>

          <div className="head_bot_inner_mid">
            <Link to="/" onClick={toTop}>
              <img
                alt=""
                src="https://wellgard.co.uk/cdn/shop/files/wellgard-logo_140x_2x_2.png?v=1631809545&width=180"
              />
            </Link>
          </div>

          <div className="head_bot_inner_right">
            <Link to="/cartpage" onClick={toTop}>
              <span>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="presentation"
                  className="icon icon-cart"
                  viewBox="0 0 64 64"
                >
                  <title>icon-cart</title>
                  <path
                    fill="#142b6f"
                    d="M14 17.44h46.79l-7.94 25.61H20.96l-9.65-35.1H3"
                  />
                  <circle cx={27} cy={53} r={2} />
                  <circle cx={47} cy={53} r={2} />
                </svg>

                {cartItems.length > 0 && (
                  <span class="cart-link__bubble cart-link__bubble--visible"></span>
                )}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
